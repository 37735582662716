import React, {useCallback} from 'react';
import {NotificationListWrapper} from './styles'
import Notification from './Notification'
import {observer} from 'mobx-react'
import useStores from '../../../../../hooks/useStores'
import NoData from './NoData'
import {Loader} from '../../../../components'
import InfiniteScroll from '../../../../modules/InfiniteScroll'

const NotificationList = () => {
  const {userStore} = useStores()
  const {
    notifications,
    readNotification,
    deleteNotification,
    notificationPagination: {totalPages, page},
    fetchNotifications,
    notificationsLoading
  } = userStore

  const handleFetch = () => {
    if (!notificationsLoading) fetchNotifications(page + 1)
  }

  const handleHover = useCallback((id: number, read: boolean) => {
    if (!read) {
      readNotification(id)
    }
  }, [])

  return (
    <NotificationListWrapper data-testid='notification-list'>
      {notifications.length
        ? <InfiniteScroll
          initialLoad={false}
          threshold={100}
          useWindow={false}
          pageStart={0}
          loadMore={handleFetch}
          hasMore={totalPages > page}
          loader={<Loader key={0}/>}
        >
          {notifications.map((item) => (
            <Notification
              key={item.id}
              id={item.id}
              type={item.type}
              createdAt={item.createdAt}
              read={item.read}
              isSending={item.isSending}
              onHover={handleHover}
              onDelete={deleteNotification}
              data={item.data}
            />
          ))}
        </InfiniteScroll>
        : <NoData/>
      }
    </NotificationListWrapper>
  );
};

export default observer(NotificationList);
