import React from 'react';
import {ComponentWrapper, Component} from './styles';

export type NavItemProps = {
  children: React.ReactNode;
  to: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const defaultProps: NavItemProps = {
  children: null,
  to: '#',
};

export const NavItem = ({children, ...rest}: NavItemProps) => {
  return (
    <ComponentWrapper {...rest}>
      <Component>{children}</Component>
    </ComponentWrapper>
  );
};

NavItem.defaultProps = defaultProps;
