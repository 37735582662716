import {lazy, useEffect, Suspense} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDirection} from "./hooks/useDirection";
import {initZendeskApi} from "./utils/zendeskIntegration";
import {Loader} from "./app/components";
import {initPlacesApi} from './utils/places';
import './App.css';
import './icons.css';
import 'react-loading-skeleton/dist/skeleton.css';
import {ProviderTheme} from './theme/providerTheme'
import {AppRouter} from "./app/routing/components";
import {registerLocale} from "react-datepicker";
import ar from 'date-fns/locale/ar';
import en from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-number-input/style.css';
import Modal from "react-modal";
import {useAuthInfo} from "./hooks/useAuthInfo";
import {useSetUser} from "./hooks/useSetUser";

const ScrollRestore = lazy(() => import('./app/components/ScrollRestore').then(module => ({default: module.ScrollRestore})));

function App() {
  const navigate = useNavigate()

  useDirection();
  useEffect(() => {
    window.navigate = navigate
    initPlacesApi();
    initZendeskApi();
    document.querySelector('.loader')?.classList.add('loaded')
    document.querySelector('body')?.classList.remove('loading')
    registerLocale('ar', ar)
    registerLocale('en', en)
    Modal.setAppElement('#root');
  }, []);

  const {...encryptedToken} = useAuthInfo()
  useSetUser(encryptedToken)

  return (
    <ProviderTheme>
      <Suspense fallback={<Loader/>}>
        <AppRouter/>
      </Suspense>
      <ScrollRestore/>
    </ProviderTheme>
  );
}

export default App;
