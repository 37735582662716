import {Icon, LinkText, Text, SocialLink} from '../../components';
import {Container} from '../Container';
import {
  FooterComponent,
  FooterBot,
  FooterSocialLinks, FooterLogo,
} from './styles';
import logo from '../../../assets/takamol-footer-logo.png';
import {useTranslation} from 'react-i18next';
import {memo} from "react";

type FooterProps = {
  children?: React.ReactNode;
};

export const Footer = memo(({children, ...rest}: FooterProps) => {
  const {t} = useTranslation();

  type SocialUnion = "instagram" | "linkedin" | "twitter" | "facebook"

  type SocialLinksProps = {
    icon: SocialUnion;
    href: string;
    target: string;
  }

  const socialLinks: SocialLinksProps[] = [
    {
      icon: 'instagram',
      href: 'footer.instagramLink',
      target: '_blank'
    },
    {
      icon: 'linkedin',
      href: 'footer.linkedInLink',
      target: '_blank'
    },
    {
      icon: 'twitter',
      href: 'footer.twitterLink',
      target: '_blank'
    },
    {
      icon: 'facebook',
      href: 'footer.facebookLink',
      target: '_blank'
    }
  ]

  return (
    <FooterComponent {...rest}>
      <Container big>
        <FooterBot>
          <div>
            <ul>
              <li>
                <Text>{t('footer.copyright')}</Text>
              </li>
              <li>
                <LinkText to='/terms'>{t('footer.termsOfUse')}</LinkText>
              </li>
              <li>
                <LinkText to='/privacy'>{t('footer.privacyPolicy')}</LinkText>
              </li>
              <li>
                <LinkText to='/faq'>{t('footer.faq')}</LinkText>
              </li>
            </ul>
          </div>
          <FooterLogo>
            <Text variant='regular-l' color='#00c48c'>
              {t('footer.poweredBy')}
            </Text>
            <div>
              <img src={logo} alt='logo'/>
            </div>
          </FooterLogo>
          <div>
            <FooterSocialLinks>
              <ul>
                {socialLinks &&
                  socialLinks.map((item, index) => {
                    return (
                      <li key={index}>
                        {
                          <SocialLink href={t(item.href)} target={item.target}>
                            <Icon name={item.icon}/>
                          </SocialLink>
                        }
                      </li>
                    )
                  })
                }
              </ul>
            </FooterSocialLinks>
          </div>
        </FooterBot>
        {children}
      </Container>
    </FooterComponent>
  );
});
