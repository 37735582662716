import {createContext, useContext} from 'react';
import stores from '../stores';

const StoresContext = createContext({
  ...stores,
});

const useStores = () => useContext(StoresContext);

export default useStores;
