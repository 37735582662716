import styled from 'styled-components';
import {HeadingVariant, TextVariant} from './index'

type ComponentProps = {
  variant: HeadingVariant;
};

type TextComponentProps = {
  variant: TextVariant;
};

export const Component = styled.div<ComponentProps>`
  ${(props) => props.theme.typography[`${props.variant}`]};
`;

export const TextComponent = styled.p<TextComponentProps>`
  ${(props) => props.theme.text[`${props.variant}`]};
  color: ${(props) =>
    props.color
      ? `${props.color} !important`
      : props.theme.text[`${props.variant}`].color};
`;
