import styled from "styled-components";
import themeMain from "../../../theme/themeMain";
import {fonts} from '../../../theme/fonts';

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-bottom: 30px;
`

export const TabMenu = styled.div`
  > button {
    cursor: pointer;
    padding: 8px 5px 7px;
    font: 600 16px/20px ${fonts.openSans};
    border: 0;
    border-bottom: 2px solid transparent;
    background: none;

    &.active,
    &:hover {
      span {
        display: block;
        position: relative;
        
        &:before{
          display: block;
          content: '';
          position: absolute;
          height: 2px;
          width: 100%;
          left: 0;
          bottom: -8px;
          background-color: ${themeMain.colors.green};
        }
      }
    }

    &:first-child {
      padding-inline-start: 0;
    }

    &:last-child {
      padding-inline-end: 0;
    }
  }
`

export const TabContent = styled.div`
  background-color: #fff;
  padding: 20px;
  min-height: 301px;
`