import styled from 'styled-components';
import themeMain from '../../../theme/themeMain';
import {fonts} from '../../../theme/fonts';
import rtl from "styled-components-rtl";

export const Label = styled.label`
  margin-bottom: 0 !important;
  cursor: pointer;
`;

export const StyledCheckbox = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  overflow: hidden;
  background: ${themeMain.colors.white};
  box-shadow: 0 0 0 1px ${themeMain.colors.gray.light} inset;
  transition: all 150ms;
  text-align: center;

  i {
    display: none;
    color: ${themeMain.colors.white};
  }

  input:focus {
    box-shadow: 0 0 0 2px ${themeMain.colors.blue};

    &:not(:checked) {
      box-shadow: none;
    }
  }
`;

export const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding-top: 1px;

  &.error {
    ${StyledCheckbox} {
      box-shadow: 0 0 0 2px ${themeMain.colors.red} inset;
    }
  }
`;


export const Component = styled.div`
  display: flex;

  span {
    font: 300 14px/18px ${fonts.openSans};
    ${rtl`margin-left: 8px;`};
  }

  label {
    &:after {
      content: '' !important;
    }
  }

  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 1px;
    cursor: pointer;

    &:focus {
      &:not(:checked) {
        & ~ ${StyledCheckbox} {
          box-shadow: 0 0 0 2px ${themeMain.colors.green} inset;
        }
      }
    }

    &:checked {
      & ~ ${StyledCheckbox} {
        background: ${themeMain.colors.green} !important;
        box-shadow: 0 0 0 1px ${themeMain.colors.green} inset;

        i {
          display: block;
        }
      }
    }

    &[disabled][checked] {
      & ~ ${StyledCheckbox} {
        background: ${themeMain.colors.green} !important;
        cursor: not-allowed;
      }
    }
  }
`;
