import {memo} from 'react';
import {NotificationItem} from './styles'
import {Text} from '../../../../components'
import moment from 'moment'
import 'moment/locale/ar'
import 'moment/locale/en-ca'
import {useTranslation} from 'react-i18next'
import {NotificationType} from '../../../../../stores/userStore'
import {NavLink} from "react-router-dom";

export type NotificationData = {
  data?: {
    title: string
    infoMessage: string
    description?: string
    jobName?: string
  }
}

type NotificationProps = NotificationData & {
  id: number
  createdAt: string
  read: boolean
  type: NotificationType
  onHover: (id: number, read: boolean) => void
  onDelete: (id: number) => void
  isSending?: boolean
}

const Notification = memo((props: NotificationProps) => {
  const {i18n: {language}, t} = useTranslation()
  const {createdAt, read, id, type, isSending, onHover, onDelete, data} = props

  const handleRead = () => {
    if (!isSending) onHover(id, read)
  }

  const handleDelete = () => {
    onDelete(id)
  }

  const renderTitle = (type: NotificationType) => {
    switch (type) {
      case 'INFO':
        return data?.title
      case 'INTERVIEW_TIME_SLOT':
        return t(`notifications.${type}.title`, {jobTitle: data?.jobName})
      default:
        return t(`notifications.${type}.title`)
    }
  }

  const renderDescription = (type: NotificationType) => {
    switch (type) {
      case 'ANNOUNCEMENT':
      case 'INFO':
        return data?.infoMessage
      case 'INTERVIEW_TIME_SLOT':
        return <span>{t(`notifications.${type}.description`, {jobTitle: data?.jobName})}
          <NavLink to={'/account/my-calendar'}>
            {t(`notifications.${type}.click`)}
          </NavLink></span>
      default:
        return t(`notifications.${type}.description`)
    }
  }

  return (
    <NotificationItem id={`${id}`}
                      data-testid='notification-item'
                      className={!read ? 'new' : ''}
                      onMouseEnter={handleRead}>
      <i data-testid='remove-btn'
         className='mi mi-e-remove remove-icon'
         onClick={handleDelete}/>
      <Text data-testid='notification-title'
            className={!read ? 'title-new' : ''}
            variant='regular-bold'>
        {renderTitle(type)}
      </Text>
      <Text variant='regular'>
        {renderDescription(type)}
      </Text>
      <Text variant='regular' className='createdAt'>
        {moment(createdAt).locale(language).fromNow()}
      </Text>
    </NotificationItem>
  );
});

export default Notification;
