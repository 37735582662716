import {InputHTMLAttributes, useState} from 'react';
import Select from 'react-select/async';
import {Component, InputWrapper} from './styles';
import {useController} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types';
import {ErrorMessage} from '@hookform/error-message';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

export type PlaceType = 'primary_school' | 'school' | 'secondary_school' | 'university'

interface PlacesInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  control: Control;
  label?: string;
  id?: string;
  placeholder?: string;
  required?: boolean;
  placeType: PlaceType | PlaceType[];
  initValue?: string;
}

export const PlacesInput = (props: PlacesInputProps) => {

  const {
    id,
    placeType,
    label,
    name,
    control,
    placeholder,
    ...inputProps
  } = props

  const {t} = useTranslation()
  const requiredText = t('common.thisIsRequired')

  const {
    field: {onChange, value},
    formState: {errors},
  } = useController({
    name,
    control,
    rules: {
      required: requiredText,
    },
  });

  const [place, setPlace] = useState<string>(value?.value || '')

  const formattedPlaceType = Array.isArray(placeType) ? placeType.join('|') : placeType

  const loadOptions = async (s: string) => {
    const res = await axios.get(
      `/textsearch/json?input=${s}&type=${formattedPlaceType}&language=en`
    );

    return res.data.results.map((r: { name: string }) => ({
      value: r.name,
      label: r.name,
    }));
  };

  const handleChange = (val: string) => {
    setPlace(val)
  }

  const handleBlur = () => {
    if (place) onChange({
      label: place,
      value: place
    })
  }

  return (
    <Component {...inputProps}>
      {label && <label htmlFor={id}>{label}</label>}
      <InputWrapper>
        <Select
          onInputChange={handleChange}
          onBlur={handleBlur}
          key={value?.value}
          onChange={onChange}
          loadOptions={loadOptions}
          defaultValue={value}
          classNamePrefix='location-select'
          isClearable
          placeholder={placeholder}
        />
      </InputWrapper>
      <ErrorMessage errors={errors} name={name} as={'p'}/>
    </Component>
  );
};
