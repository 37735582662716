import styled from 'styled-components';
import {devices} from '../../../theme/devices';
import themeMain from '../../../theme/themeMain';
import rtl from "styled-components-rtl";

export const FooterComponent = styled.footer`
  display: flex;
  background-color: #0a1725;
  padding: 32px 0;
`;

export const FooterBot = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  > div {
    &:last-child {
      width: 100%;

      @media screen and ${devices.tablet} {
        width: auto;
      }
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    padding: 0;

    li {
      ${ rtl`margin-right: 12px;` };
      @media screen and ${devices.laptop} {
        ${ rtl`margin-right: 32px;` };
      }

      &:last-child {
        ${ rtl`margin-right: 0;` };

        @media screen and ${devices.laptop} {
          ${ rtl`margin-right: 0;` };
        }
      }

      a {
        font-size: 16px;
        color: ${themeMain.colors.white};
        line-height: 22px;
      }

      p {
        color: ${themeMain.colors.white};
        font-size: 16px;
        margin-bottom: 0;
      }
    }
  }
`;

export const FooterSocialLinks = styled.div`
  ul {
    justify-content: center;

    @media ${devices.tablet} {
      justify-content: flex-start;
    }

    li {
      ${ rtl`margin-right: 10px;` };

      &:last-child {
      ${ rtl`margin-right: 0;` };
      }
    }
  }
`;

export const FooterLogo= styled.div`
  display: flex;
  align-items: center;
  
  p{
    min-width: 84px;
    ${ rtl`margin-right: 10px;` };
    margin-bottom: 4px;
  }
`