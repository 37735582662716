import api from "../../../../../../api/axiosInterceptor";
import {
  CREATE_RECEPTIONIST,
  CREATE_TIME_SLOTS,
  GET_RECEPTIONISTS_LIST,
  GET_ATTENDEES_LIST,
  GET_RECEPTIONIST_DETAILS
} from "../../../../../../constants/api";
import {TimeSlotPayload} from "./types";
import {ReceptionistPayload} from "../../Receptionist/types";
import {PaginationPayload} from "../../../../../../types";

export const addTimeSlot = async (data: TimeSlotPayload) => {
  try {
    const response = await api.post(CREATE_TIME_SLOTS, {
      ...data
    })
    return response.data
  } catch (e) {
    return Promise.reject(e.response);
  }
}

export const addReceptionist = async (data: ReceptionistPayload) => {
  try {
    const response = await api.post(
      CREATE_RECEPTIONIST,
      data
    );
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getAllReceptionists = async (data: PaginationPayload) => {
  try {
    const response = await api.post(GET_RECEPTIONISTS_LIST, {
      size: 9,
      ...(data.page && {page: +data.page - 1}),
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getReceptionist = async (id: string | number) => {
  try {
    const response = await api.get(`${GET_RECEPTIONIST_DETAILS}/${id}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getAttendees = async (data: PaginationPayload) => {
  try {
    const response = await api.post(GET_ATTENDEES_LIST, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};


