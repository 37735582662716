import styled from 'styled-components';
import {fonts} from '../../../theme/fonts';
import themeMain from '../../../theme/themeMain';
import rtl from "styled-components-rtl";

export const Component = styled.div`
  height: auto;

  label {
    display: block;
    font: 700 14px/16px ${fonts.openSans};
    color: ${themeMain.colors.black};
    margin-bottom: 11px;
  }

  textarea {
    ${(props) => props.theme.input};
    color: ${themeMain.colors.black};

    &:focus,
    &:focus-visible {
      border-color: ${themeMain.colors.blue};
      box-shadow: none;
    }

    &::placeholder {
      color: ${themeMain.colors.gray.medium};
    }

    &:disabled {
      background: #f8f8f8;
      border: 1px solid #e2e4e8;
      cursor: not-allowed;
    }

    &.error {
      border-color: red;
    }
  }

  textarea {
    resize: none;
    height: auto !important;
    max-height: initial !important;
    line-height: 1.5;
  }

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${rtl`right: 17px;`};
  }
`;

export const InputWrapper = styled.div`
  position: relative;

  textarea + span {
    display: inline-block;
    position: absolute;
    ${rtl`right: 0;`};
    bottom: -24px;
  }
`;
