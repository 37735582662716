import React, {useEffect, useRef} from 'react';
import {observer} from 'mobx-react'
import useStores from '../../../../../hooks/useStores'
import {ReactComponent as NotificationIcon} from '../../../../../assets/svg/notification.svg'
import {ReactComponent as NotificationIconWhite} from '../../../../../assets/svg/notification-white.svg'
import {NotificationIconWrapper, NotificationRedDot, NotificationWrapper} from '../../../../layouts/Header/styles'
import Notifications from './index'
import {useOnClickOutside} from '../../../../../hooks/useClickOutside'
import bodyOverflow from '../../../../../utils/bodyOverflow'
import {useScreenSize} from '../../../../../hooks/useScreenSize'
import {useAuthInfo} from '../../../../../hooks/useAuthInfo'
import {Role} from '../../../../../utils/roles'

const NotificationBell = ({isLanding = false}) => {
  const isTablet = useScreenSize(768)
  const {userStore} = useStores()
  const {hasNewNotification, setIsNotificationOpen, isNotificationOpen} = userStore
  const ref = useRef<HTMLDivElement>(null)
  const {...encryptedToken} = useAuthInfo()

  const handleClose = () => {
    setIsNotificationOpen(false)
  }

  const handleShow = () => {
    setIsNotificationOpen(!isNotificationOpen)
  }

  useOnClickOutside(ref, handleClose)

  useEffect(() => {
    if (isTablet && isNotificationOpen) {
      bodyOverflow(isNotificationOpen)
    } else {
      bodyOverflow(false)
    }
  }, [isTablet, isNotificationOpen])

  return (
    <NotificationWrapper ref={ref}>
      {(encryptedToken.role === Role.jobSeeker || encryptedToken.role === Role.employer ) && <>
          <NotificationIconWrapper
              isLanding={isLanding}
              data-testid='notification-icon-wrapper'
              className={isNotificationOpen ? 'open' : ''}
              onClick={handleShow}
          >
            {hasNewNotification && <NotificationRedDot data-testid='notification-icon-dot'/>}
            {isLanding ? <NotificationIconWhite/>:<NotificationIcon/>}
          </NotificationIconWrapper>
          <Notifications/>
      </>
      }
    </NotificationWrapper>
  );
};

export default observer(NotificationBell);
