// Other
export const GET_MEDIA = (url: string) => `/media/file/${url}`

// Auth
export const UPDATE_EMPLOYER_PASSWORD = '/auth/update-password'
export const PROFILE_AVATAR = '/profile/v2/avatar'
export const PROFILE_INFO = '/profile/personal'
export const GET_PROFILE_COMPLETENESS = '/profile/completeness'
export const GET_PROFILES = '/profile/v1/admin/search'
export const GET_JOB_SEEKERS_EMPLOYER = '/profile/v1/employer/search'
export const GET_JO_PROFILES = '/job/v1/search/admin'
export const GET_USER_HISTORY = '/history/v1'
export const GET_COMMITTEE_MEMBERS_BY_ADMIN = '/user/committee/list/admin'
export const EDIT_COMMITTEE_BY_ADMIN = '/user/committee'

//admin
export const SEND_ANNOUNCEMENTS = 'notification/announcement'
export const SEND_FILTERED_GOV_ANNOUNCEMENTS = 'user/employer/announcement'
export const SEND_FILTERED_JS_ANNOUNCEMENTS = 'profile/announcement'

// Jobseeker Resume endpoints
export const GET_ABOUT_ME_JS = '/profile/aboutme'
export const GET_CERTIFICATES_JS = (id: number) => `/profile/certificate?userId=${id}`
export const GET_EDUCATION_JS = (id: number) => `/profile/education?userId=${id}`
export const GET_LANGUAGES_JS = '/profile/language'
export const GET_PERSONAL_INFO_JS = '/profile/personal'
export const GET_SKILL_JS = '/profile/skill'
export const GET_EXPERIENCE_JS = '/profile/experience'
export const GET_PROFILE_JS = '/profile'
export const POST_WIZZARD_INFO = '/profile/flags/wizard-passed'
export const SINGLE_UPLOAD_RESUME_ATTACHMENTS = '/profile/v2/attachment'
export const RESUME_ATTACHMENTS_ID_LIST = '/profile/v2/attachment/bind'

// employer Resume endpoints
export const GET_ABOUT_ME = (id: string) => `/profile/aboutme/${id}`
export const GET_CERTIFICATES = (id: string) => `/profile/certificate/user/${id}`
export const GET_EDUCATION = (id: string) => `/profile/education/user/${id}`
export const GET_LANGUAGES = (id: string) => `/profile/language/user/${id}`
export const GET_PERSONAL_INFO = (id: string) => `/profile/personal/${id}`
export const GET_SKILL = (id: string) => `/profile/skill/user/${id}`
export const GET_EXPERIENCE = (id: string) => `/profile/experience/user/${id}`
export const GET_PROFILE = (id: string) => `/profile/${id}`

//Job Opening endpoints
export const GET_JOB = (id: string) => `/job/${id}`
export const GET_JOB_OPENINGS = '/job/v1/search/js'
export const JOB_OPENING_AVATAR = '/media/file'
export const GET_JOB_APPLICATIONS = '/job/application/v1/filter'
export const GET_APPLIED_JS = '/profile/v1/search'
export const GET_INTERVIEW_TAB = '/interview/job-seekers/invitations/job-opening'
export const POST_JOB_OPENING = '/job/v1'
export const PUT_JOB_OPENING_LOGO = '/job/v1/logo'

//CommitteeCalendar endpoints
export const GET_NEW_CALENDAR_EVENTS = 'interview/invitations/new'
export const REJECT_CALENDAR_EVENT = 'interview/invitations/reject'
export const GET_APPLIED_EVENTS = '/event/search-applied'
export const GET_COMMITTEE_TIME_SLOTS = '/event/search-applied'
export const GET_INVITATION_TIME_SLOTS = 'interview/slot/search'
export const SELECT_INTERVIEW_SLOT = '/interview/slot'

//LAOInterview endpoints
export const GET_INTERVIEW = (id: string) => `/interview/${id}`
export const GET_INTERVIEW_LOCATION = (id: string) => `/interview/${id}/location`
export const CREATE_ONLINE_INTERVIEW = '/interview'
export const GET_CALENDAR_EVENT_LOCATION = (id:string) => `/interview/${id}/location`
export const BULK_DELETE_INTERVIEWS = '/interview/cancel/bulk'
export const CREATE_TIME_SLOTS ='interview/slot'

//Offer endpoints
export const GET_OFFER = (id: string) => `/offer/${id}`
export const CREATE_OFFER = '/offer'
export const EDIT_OFFER = (id: string) => `/offer/${id}/status`
export const GET_APPROVED_APPLICANTS = '/job/evaluation/v1/approved/list '
export const CHECK_OFFER_EXIST = (openingId: string, jsId: string) => `/offer/job/${openingId}/job-seeker/${jsId}`
export const GET_EVALUATION_INFO = (joId: string, jsId: string) => `/job/evaluation/v1/admin-committee/job-opening/${joId}/job-seeker/${jsId}`

//Notifications
export const GET_NOTIFICATIONS = '/notification/v1'
export const READ_ALL = '/notification/v1/read'
export const READ_NOTIFICATION = (id: number) => `/notification/v1/${id}/read`
export const DELETE_NOTIFICATION = (id: number) => `/notification/v1/${id}`
export const SEND_NOTIFICATION_TO_JS = '/notification/create'

//Government endpoints
export const GOV_EMPLOYER = '/user/employer/v1'
export const GET_GOV_EMPLOYERS = '/user/employer/v1/search'

// employer JO endpoints
export const GET_EMPLOYER_JOB_OPENINGS = '/job/v1/list'
export const PUT_APPLICANTS = (jobId: string | number) => `/job/${jobId}/application/v1`
export const BLACKLIST_APPLICANT = `/job/blacklist/labor-application`
export const GET_PRIVATE_JO = '/job/search/private'
export const SEND_PRIVATE_INVITE_JO = (data: {jobId: number, jobSeekerId: number | string}) => `/job/${data.jobId}/application/shortlisted/${data.jobSeekerId}`
// Labor Attaché Office
export const GET_HIRING_REQUESTS = '/job/hiring-request/list'
export const GET_HIRING_REQUEST = (id?: string) => `/job/hiring-request/${id}`
export const CREATE_COMMITTEE = '/user/v1/committee'
export const GET_COMMITTEE_LIST = '/user/v1/committee/list'
export const GET_ADMINISTRATIVE_LIST = '/user/admin-committee/list'
export const GET_HIRING_COMMITTEE_LIST = (id:number) => `/job/hiring-request/v1/${id}/committee`
export const GET_HIRING_HEAD_LIST = (id:number) => `/job/hiring-request/v1/${id}/head`
export const GET_HIRING_ADMINISTRATIVE_LIST = (id:number) => `job/hiring-request/v1/${id}/admin-committee`
export const POST_HIRING_REQUEST = '/job/hiring-request/v1'
export const POST_HIRING_FILE = '/job/hiring-request/file'
export const GET_REJECTED_APPLICANTS = '/job/evaluation/v1/rejected/list'
export const GET_BLACKLIST_LIST_BY_LAO = '/job/blacklist/search'
export const GET_BLACKLIST_LIST_BY_LA = '/job/blacklist/search'
export const REMOVE_BLACKLIST_APPLICANT = '/job/blacklist/removal-request'
export const GET_PASSPOOL_LIST = '/job/pass-pool'
export const CREATE_RECEPTIONIST = '/user/receptionist'
export const GET_RECEPTIONISTS_LIST = '/user/receptionist/list'
export const GET_ATTENDEES_LIST ='/event/attendees'
export const GET_PASSPORT_TRAVEL_JO_LIST = 'job/passport-and-travel'
export const GET_PASSPORT_TRAVEL_JS_LIST = (id: string | number) => `job/passport-and-travel/job-opening/${id}/confirmed-applicants`
export const GET_PASSPORT_TRAVEL_DOCUMENTS_BY_ID = (jsId: string | number, joId: string |number) => `job/passport-and-travel/documents/job-seeker/${jsId}/job-opening/${joId}/list`
export const POST_PASSPORT_TRAVEL_FILE = '/job/passport-and-travel/documents/upload'
export const DELETE_PASSPORT_TRAVEL_DOC = (docId: string | number) => `job/passport-and-travel/documents/${docId}`
export const GET_PASSPORT_TRAVEL_EMAILS = (id: string | number) => `job/${id}/recipients`

// Receptionist endpoints
export const GET_PARTICIPANT_DETAILS = '/interview/participant'
export const GET_RECEPTIONIST_DETAILS = '/user/receptionist'

// Administrative committee
export const EDIT_CANDIDATE_DETAILS = `/job/evaluation/admin-committee/review`
