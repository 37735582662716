import apiWrapper from "../../../api/axiosInterceptor";
import React, {useEffect, useState} from "react";
import {Buffer} from "buffer";
import {Loader} from "../../components";

type ImageProps = {
  src: string;
  alt?: string;
  mockSrc?: string;
  style?: React.CSSProperties;
  loaderStyle?: React.CSSProperties;
  loaderSize?: 'small' | 'normal'
}

export const Image = ({
                        src,
                        alt = 'Image',
                        mockSrc,
                        loaderStyle,
                        loaderSize,
                        ...rest
                      }: ImageProps) => {
  const [loading, setLoading] = useState(false)
  const [imgSrc, setImgSrc] = useState(mockSrc ?? '');

  const onFetch = async () => {
    setLoading(true)
    try {
      const res = await apiWrapper.get(
        src,
        {
          responseType: 'arraybuffer',
        })
      const imgRes = Buffer.from(res.data, 'binary').toString('base64')
      setImgSrc(`data:image/jpeg;charset=utf-8;base64,${imgRes}`)
    } catch (e) {
      if (mockSrc) setImgSrc(mockSrc)
      console.log('image fetch error', e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (src) onFetch()
    // eslint-disable-next-line
  }, [src]);

  if (loading) return <Loader style={loaderStyle} loaderSize={loaderSize}/>

  return (
    <>
      {imgSrc ?
        <img data-testid='img' src={imgSrc} alt={alt} {...rest}/> : null}
    </>
  )
}
