import styled from 'styled-components';
import {fonts} from '../../../theme/fonts';
import themeMain from '../../../theme/themeMain';

export const Component = styled.div`
  height: auto;

  &.error {
    .location-select__control {
      border-color: red !important;
    }
  }

  label {
    display: block;
    font: 700 14px/16px ${fonts.openSans};
    color: ${themeMain.colors.black};
    margin-bottom: 11px;
  }

  input {
    ${(props) => props.theme.input};
    color: ${themeMain.colors.black};
      
    &:focus {
      border-color: ${themeMain.colors.blue} !important;
    }

    &::placeholder {
      color: ${themeMain.colors.gray.medium};
    }

    &:disabled {
      background: #e9edf2;
      border: 1px solid #e2e4e8;
      cursor: not-allowed;
    }

    &.error {
      border-color: red;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;