import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import en from './translations/en'
import ar from './translations/ar'

const resources = {
  en: {
    translation: {
      ...en
    }
  },
  ar: {
    translation: {
      ...ar
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
