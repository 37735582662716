import React, {useState, KeyboardEventHandler, useEffect, InputHTMLAttributes} from 'react';
import Creatable from 'react-select/creatable';
import {OnChangeValue, ActionMeta} from 'react-select';
import {Control, Controller} from 'react-hook-form';

type Option = {
  readonly label: string;
  readonly value: string;
};
const components = {
  DropdownIndicator: null,
}

interface MultiInputProps extends InputHTMLAttributes<HTMLInputElement>{
  name: string
  control: Control
  label: string
  setValue: (name: string, value: Array<string>) => void
  className?: string
}

export const MultiInput = React.forwardRef<HTMLInputElement, MultiInputProps>(
  ({name, control, label, className, setValue}) =>  {
  const [options, setOptions] = useState<Option[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (value: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => {
    switch (actionMeta.action) {
      case 'clear':
        setOptions([]);
        break;
      case 'remove-value':
      case 'pop-value':
        setOptions([...value]);
        break;
      default:
        setOptions((prev) => [...prev, ...value]);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (!inputValue) return;
    if (options.some((e) => e.value === inputValue)) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setInputValue('');
        setOptions((prev) => [...prev, {label: inputValue, value: inputValue}]);
        event.preventDefault();
    }
  };

  useEffect(() => {
    const formattedOptions = options.map(item => item.value)
    setValue(name, formattedOptions)
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={() => (
        <>
          <label htmlFor={name}>{label}</label>
          <Creatable
            components={components}
            onInputChange={setInputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputValue={inputValue}
            classNamePrefix="r-select"
            placeholder={label}
            inputId={name}
            name={name}
            isClearable
            isMulti
            menuIsOpen={false}
            value={options}
            className={className}
          />
        </>
      )}
    />
  );
})
