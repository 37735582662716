import  {useMemo} from 'react';
import {
  ProgressBarWrapper,
  ProgressBarLine,
  ProgressBarFilling,
  ProgressBarBtn,
  ProgressBarTitleWrapper,
  Title
} from './styles'
import {mandatoryPercents} from '../../../stores/userStore'

export type ProgressBarProps = {
  title: string
  progress: number
}

export const ProgressBar = (props: ProgressBarProps) => {
  const {title, progress} = props
  let color = ''

  if (progress < 80) {
    color = '#FF8000';
  }
  if (progress >= 80 && progress < 100) {
    color = '#ADD8E6';
  }
  if (progress === 100) {
    color = '#00C48C';
  }

  const unlocked = useMemo(() => progress >= mandatoryPercents, [progress])
  return (
    <div>
      <ProgressBarTitleWrapper>
        <Title>
          {title}
        </Title>
        <Title>
          {progress}%
        </Title>
      </ProgressBarTitleWrapper>
      <ProgressBarWrapper>
        <ProgressBarLine>
          <ProgressBarFilling color={color} style={{width: `${progress}%`}}/>
          <ProgressBarBtn className={unlocked ? 'unlocked' : ''}>
            <i className={unlocked ? 'mi mi-unlocked' : 'mi mi-i-lock'}/>
          </ProgressBarBtn>
        </ProgressBarLine>
      </ProgressBarWrapper>
    </div>
  );
};