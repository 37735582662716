import styled, {css} from 'styled-components';
import {devices} from '../../../theme/devices';
import rtl from "styled-components-rtl";

type ContainerProps = {
  big?: boolean
}

export const ContainerComponent = styled.div<ContainerProps>`
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;

  @media screen and ${devices.tablet} {
    width: 768px;
    padding: 0 30px;
  }

  @media screen and ${devices.laptop} {
    width: 992px;
  }

  @media screen and ${devices.desktop} {
    width: 1146px;
  }

  ${({big}) =>
          big &&
          css`
            @media screen and (min-width: 1366px) {
              width: 1366px;
            }
          `}
`;

export const HeaderLinkGroupComponent = styled.div`
  display: flex;

  @media screen and ${devices.laptop} {
    align-items: center;

    ${rtl`margin-left: auto;`};
  }

  > span {
    ${rtl`margin-right: 10px;`};

    svg {
      transform: translateY(3px);
    }
  }

  button,
  a {
    & + button,
    & + a {
      ${rtl`margin-left: 10px;`};
    }
  }
`;

export const HeaderContainerComponent = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 14px 15px;
  min-height: 70px;
  align-items: center;

  @media screen and ${devices.tablet} {
    padding: 14px 30px 5px;
    width: 100%;
  }
  
  @media screen and ${devices.laptop} {
    width: 100%;
    max-width: 1366px;
  }
`;
