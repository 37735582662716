import {InputHTMLAttributes, useEffect, useState} from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
} from 'react-places-autocomplete';
import {Component, InputWrapper} from './styles';
import {useController} from 'react-hook-form';
import {Control} from 'react-hook-form/dist/types';
import {ErrorMessage} from '@hookform/error-message';
import {useTranslation} from 'react-i18next';
import {demoORstageEnv} from "../../../utils/places";

export interface PlacesInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  control: Control
  label?: string
  id?: string
  placeholder?: string
  required?: boolean
  variant: string
}

export const LocationsInput = ({
  id,
  variant,
  label,
  name,
  control,
  required,
  disabled,
  ...inputProps
}: PlacesInputProps) => {
  const {t} = useTranslation()
  const requiredText = t('common.thisIsRequired')

  const {
    field: {onChange, value},
    formState: {errors},
    fieldState: {isDirty},
  } = useController({
    name,
    control,
    rules: {
      required: required ? requiredText : '',
    },
  });

  const [address, setAddress] = useState(value);

  const searchOptionsEstablishment = {
    types: ['establishment'],
  };

  const searchOptionsLocation = {
    types: ['(cities)'],
  };

  const handleChange = (val: string) => {
    setAddress(val);
    onChange(val);
  };

  const handleSelect = async (address: string) => {
    if (!address) return;
    const res = await geocodeByAddress(address);
    setAddress(variant === 'location' ? res[0].formatted_address : address);
    onChange(variant === 'location' ? res[0].formatted_address : address);
  };

  useEffect(() => {
    if (!address && isDirty) {
      onChange('');
    }
    // eslint-disable-next-line
  }, [address]);

  useEffect(() => {
    if (value) setAddress(value);
  }, [value]);

  return (
    <Component {...inputProps}>
      {label && <label htmlFor={id}>{label}</label>}
      <InputWrapper>
        <PlacesAutocomplete
          value={address || ''}
          onChange={handleChange}
          onSelect={handleSelect}
          searchOptions={
            variant === 'location'
              ? searchOptionsLocation
              : searchOptionsEstablishment
          }
          // searchOptions={{ componentRestrictions: {country: "us"}, }}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div>
              <input
                {...getInputProps({
                  placeholder: t('locationsInput.searchPlaces'),
                  className: `location-search-input ${
                    errors[name] ? 'error' : ''
                  } `,
                })}
                disabled={disabled}
              />
              <div className='autocomplete-dropdown-container'>
                {suggestions.map((suggestion) => {
                  const className = suggestion.active
                    ? 'suggestion-item--active'
                    : 'suggestion-item';
                  const style = suggestion.active
                    ? {
                        backgroundColor: 'rgba(38, 132, 255, 0.08)',
                        cursor: 'pointer',
                      }
                    : {backgroundColor: '#ffffff', cursor: 'pointer'};
                  return (
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className,
                        style,
                      })}
                      key={suggestion.placeId}
                    >
                      <span>{suggestion.description}</span>
                    </div>
                  );
                })}
                {!demoORstageEnv && loading && <div>{t('common.loading')}</div>}
              </div>
            </div>
          )}
        </PlacesAutocomplete>
      </InputWrapper>
      <ErrorMessage errors={errors} name={name} as={'p'} />
    </Component>
  );
};
