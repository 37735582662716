import React from 'react';
import {FieldsetComponent} from './styles';

type FieldsetProps = {
  children: React.ReactNode
  separator?: 'double' | 'tripple' | 'quadro'
  className?: string
};

export const Fieldset = ({children, separator = "double", ...rest}: FieldsetProps) => {
  return (
    <FieldsetComponent separator={separator} {...rest}>
      {children}
    </FieldsetComponent>
  );
};
