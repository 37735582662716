import styled, {css} from 'styled-components';
import {fonts} from '../../../theme/fonts';
import themeMain from '../../../theme/themeMain';
import rtl from "styled-components-rtl";

type FormItemComponentProps = {
  mandatory?: boolean;
};

export const Component = styled.div`
  height: auto;


  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    ${rtl`right: 17px;`};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputHintComponent = styled.div`
  display: flex;

  i {
    font-size: 18px;
    color: ${themeMain.colors.green};
    ${rtl`margin-left: 5px;`};
  }
`;

export const InputVerifiedComponent = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-size: 10px;
    font-weight: bold;
    color: ${themeMain.colors.green};
  }

  i {
    font-size: 12px;
    color: ${themeMain.colors.green};
    ${rtl`margin: 0 3px 0 auto;`};


  }
`;

export const FormItemComponent = styled.div<FormItemComponentProps>`
  margin-bottom: 22px;

  ${({mandatory}) =>
          mandatory &&
          css`
            label {
              position: relative;

              &:after {
                content: '*';
                color: ${themeMain.colors.red};
              }
            }
          `}
  input {
    ${(props) => props.theme.input};
    color: ${themeMain.colors.black};

    &:focus,
    &:focus-visible {
      border-color: ${themeMain.colors.blue} !important;
      box-shadow: none;
    }

    &::placeholder {
      color: ${themeMain.colors.gray.medium};
    }

    &:disabled {
      background: ${themeMain.colors.whiteSmoke};
      border: 1px solid #e2e4e8;
      cursor: not-allowed;
    }

    &.error {
      border-color: red;
    }
  }

  label {
    display: block;
    font: 700 14px/16px ${fonts.openSans};
    color: ${themeMain.colors.black};
    margin-bottom: 11px;
  }

  p {
    margin: 8px 0 0;
    color: ${themeMain.colors.red};
  }

  input {
    &.PhoneInputInput {
      outline: none;
      height: 38px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1;
      padding: 9px 13px 9px 13px;
      background: #fff;
      border: 1px solid #d1d5db;
      box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
      ${rtl`border-radius: 0 4px 4px 0;`};
      width: 100%;
      ${rtl`text-align: left;`};

      &:focus,
      &:focus-visible {
        border-color: ${themeMain.colors.blue};
        box-shadow: none;
      }
    }
  }
`;

export const InputNumber = styled.input`
  width: 75px;
  height: 40px;
  background: ${themeMain.colors.white};
  border: 1px solid ${themeMain.colors.gray.light};
  border-radius: 4px;
  padding: 10px;
  color: ${themeMain.colors.black};
  outline: none;

  /* Chrome, Safari, Edge, Opera */
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    /* Firefox */
    -moz-appearance: textfield;
  }


  &:focus,
  &:focus-visible {
    border-color: ${themeMain.colors.blue} !important;
    box-shadow: none;
  }

  &::placeholder {
    color: ${themeMain.colors.gray.medium};
  }

  &:disabled {
    background: ${themeMain.colors.whiteSmoke};
    border: 1px solid #e2e4e8;
    cursor: not-allowed;
  }

  input {
    &.error {
      border-color: red;
    }
  }
`;