import {useMutation, useQueryClient} from 'react-query';
import {
  login,
  loginOtp,
  logout,
  updateEmployerPassword
} from './requests';
import {ServerError} from "../../../../api/types";
import {UpdateEmployerPasswordPayload} from "./types";

export const useLogin = () => {
  return useMutation(login, {
    onSuccess: (response) => {
      return response;
    },
    onError: ({data}) => {
      return data;
    },
  });
};

export const useUpdatePassword = () => {
  return useMutation<unknown, ServerError, UpdateEmployerPasswordPayload>(updateEmployerPassword)
}

export const useLoginOtp = () => {
  return useMutation(loginOtp, {
    onSuccess: (response) => {
      return response;
    },
    onError: ({data}) => {
      return data;
    },
  });
};

export const useLogout = () => {
  const queryClient = useQueryClient();

  return useMutation(logout, {
    onSuccess: (response) => {
      queryClient.clear()
      return response;
    },
    onError: ({data}) => {
      return data;
    },
  });
};

