import {Link, Wrapper} from './styles'
import {ReactComponent as ArrLeft} from '../../../assets/svg/arr-left.svg'

export type BackButtonProps = {
  text: string
  to?: string
  onClick?: () => void
}

export const BackButton = (props: BackButtonProps) => {
  const {text, to, onClick} = props
  return (
    <Wrapper>
      <Link to={to} onClick={onClick}>
        <ArrLeft/>
        {text}
      </Link>
    </Wrapper>
  );
};