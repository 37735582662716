import styled, {css} from 'styled-components'
import themeMain from '../../../theme/themeMain'

type LoaderProps = {
  loaderSize: 'small' | 'normal'
}

export const LoaderWrapper = styled.div<LoaderProps>`
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  
  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid ${themeMain.colors.green};
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${themeMain.colors.green} transparent transparent transparent;
  }
  & div:nth-child(1) {
    animation-delay: -0.45s;
  }
  & div:nth-child(2) {
    animation-delay: -0.3s;
  }
  & div:nth-child(3) {
    animation-delay: -0.15s;
  }


  ${({loaderSize}) =>
          loaderSize === 'small' &&
          css`
            height: 40px;
            width: 40px;
            > div {
              height: 34px;
              width: 34px;
              margin: 2px;
              border-width: 4px;
            }
          `}
  
  @keyframes lds-ring{
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
