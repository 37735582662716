import {ThemeProvider} from 'styled-components';
import themeMain from './themeMain';

type ProviderThemeProps = {
  children?: React.ReactNode;
};

export const ProviderTheme = ({children,}: ProviderThemeProps): JSX.Element => {
  const themeMainOptions = {
    ...themeMain,
    dir: document.body.dir
  }

  return <ThemeProvider theme={themeMainOptions}>{children}</ThemeProvider>;
};
