import api from "../../../../../api/axiosInterceptor";
import {PaginationPayload, ResponseWithPagination} from "../../../../../types";
import {GetJoResponse} from "../../../employer/JoList/types";
import {
  GetCommitteeInfo,
  GetCommitteeJoPayload,
  GetEvaluationListPayload,
  GetEvaluationReview,
  GetEvaluationReviewByHead,
  GetEvaluationsHeadListResponse,
  GetEvaluationsListResponse,
  PostEvaluationPayload,
  PostHeadEvaluationPayload,
  PostHeadReevaluationPayload
} from "./types";
import {
  EVALUATION_BY_HEAD,
  HEAD_OF_COMMITTEE_LIST
} from "../../../../../constants/routes";

export const getCommitteeJo = async (payload: GetCommitteeJoPayload) => {
  const {data} = await api.post<ResponseWithPagination<GetJoResponse[]>>('job/v1/search/labor', {
    ...payload,
    ...(payload.page && {page: +payload.page - 1}),
    ...(payload.statusCriterias && {statusCriterias: Array.isArray(payload.statusCriterias) ? payload.statusCriterias : [payload.statusCriterias]})
  })
  return data
}

export const getEvaluationsList = async (payload: GetEvaluationListPayload) => {
  const {data} = await api.post<ResponseWithPagination<GetEvaluationsListResponse[]>>('/job/evaluation/committee/list', {
    ...payload,
    ...(payload.page && {page: +payload.page - 1}),
  })
  return data
}

export const getEvaluationsHeadList = async (payload: PaginationPayload) => {
  const {data} = await api.post<ResponseWithPagination<GetEvaluationsHeadListResponse[]>>(HEAD_OF_COMMITTEE_LIST, {
    ...payload,
    ...(payload.page && {page: +payload.page - 1}),
  })
  return data
}

export const getEvaluation = async (id: number) => {
  const {data} = await api.get<GetEvaluationsListResponse>(`/job/evaluation/${id}`)
  return data
}

export const getEvaluationReview = async (id: number) => {
  const {data} = await api.get<GetEvaluationReview>(`/job/evaluation/v1/${id}/review`)
  return data
}

export const getEvaluationReviewByHead = async (id: number) => {
  const {data} = await api.get<GetEvaluationReviewByHead>(`${EVALUATION_BY_HEAD}/${id}`)
  return data
}

export const getCommitteeInformation = async (id: number) => {
  const {data} = await api.get<GetCommitteeInfo>(`/user/v1/committee/${id}`)
  return data
}

export const createEvaluationReview = async (data: PostEvaluationPayload) => {
  try {
    const res =  await api.post(`/job/evaluation/review`, data)
    return res.data
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export const editEvaluationReview = async (data: PostEvaluationPayload) => {
  try {
    const res =  await api.put(`/job/evaluation/review`, data)
    return res.data
  } catch (error) {
    return Promise.reject(error.response);
  }
}

export const createHeadEvaluationReview = async (data: PostHeadEvaluationPayload) => {
  return await api.post(`/job/evaluation/v1/head/review`, data)
}

export const updateHeadEvaluationReview = async (data: PostHeadReevaluationPayload) => {
  return await api.post(`/job/evaluation/head/reevaluation-request`, data)
}