import React, {MouseEventHandler} from 'react';
import {Component} from './styles';

export type LinkProps = {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary';
  to: string;
  size?: 'big' | 'medium' | 'small';
  style?: React.CSSProperties;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean
  target?: string
};

const defaultProps: LinkProps = {
  children: null,
  variant: 'primary',
  to: '#'
};

export const LinkButton = ({children, variant = 'primary', size, disabled, ...rest}: LinkProps) => {
  return (
    <Component className={disabled ? 'disabled' : ''} variant={variant} size={size} {...rest}>
      {children}
    </Component>
  );
};

LinkButton.defaultProps = defaultProps;
