import {
  PaginationPayload,
  ResponseWithPagination
} from "../../../../../../types";
import {useQuery} from "react-query";
import {ServerError} from "../../../../../../api/types";
import {getAllReceptionists, getAttendees, getReceptionist} from "./requests";
import {ReceptionistResponse, AttendeeResponse} from "./types";

export const useGetReceptionistList = (data: PaginationPayload) => (
  useQuery<ResponseWithPagination<ReceptionistResponse[]>, ServerError>(
    ['receptionistsList', data],
    () => getAllReceptionists(data),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true
    })
)

export const useGetReceptionist = (id: string | number, isReceptionist: boolean) => (
  useQuery<{name: string}, ServerError>(
    ['receptionist', id],
    () => getReceptionist(id),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      staleTime: Infinity,
      enabled: isReceptionist
    })
)

export const useGetAttendeesList = (data: PaginationPayload) => (
  useQuery<ResponseWithPagination<AttendeeResponse[]>, ServerError>(
    ['attendeesList', data],
    () => getAttendees({...data, page: data.page === 0 ? 0: Number(data.page) - 1}),
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true
    })
)
