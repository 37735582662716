import {useLayoutEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const ScrollRestore = () => {
  const {pathname, search} = useLocation();

  useLayoutEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
      });
    }, 0)

  }, [pathname, search]);

  return null;
}