import {useDirection} from "../../../hooks/useDirection";
import {ChangeLangButton} from "./styles";

type LangSwitchProps = {
  isLanding: boolean
}

export const LangSwitch = (props: LangSwitchProps) => {
  const {isLanding} = props
  const {setLang, lang} = useDirection();

  return (
    <>
      <ChangeLangButton isLanding={isLanding}
                        onClick={() => setLang(lang === 'en' ? 'ar' : 'en')}>
        {lang === 'en' ? 'العربية' : 'English'}
      </ChangeLangButton>
    </>
  )
}