import styled from 'styled-components';
import themeMain from '../../../theme/themeMain';
import {devices} from '../../../theme/devices'

export const AnalyticsBoxComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 38px 20px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
  background-color: ${themeMain.colors.white};

  @media screen and ${devices.tablet} {
    min-height: 256px;
  }
`;

export const AnalyticsNumberComponent = styled.p`
  margin: 0;
  font-size: 80px;
  line-height: 1.33;
  color: ${themeMain.colors.green};
`

export const AnalyticsMonthComponent = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 2.14;
  color: ${themeMain.colors.gray.dark};
`

export const AnalyticsTitleComponent = styled.h2`
  margin: 0 0 5px;
  font-size: 24px;
  line-height: 30px;
  font-weight: bold;
  color: ${themeMain.colors.black};
`
