import React from 'react';
import PhoneInput, {Country, Flags} from "react-phone-number-input";
import {useController} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {Control} from "react-hook-form/dist/types";
import {E164Number} from "libphonenumber-js/types";
import {ErrorMessage} from "@hookform/error-message";
import saudiFlag from '../../../assets/saudiFlag.png';
import {CountryFlagWrapper} from "./style";

interface InputProps {
  children?: React.ReactNode;
  name: string;
  label?: string;
  id?: string;
  placeholder?: string;
  required?: boolean;
  dir?: string;
  control: Control;
  onChange?: (value: E164Number) => void;
  className?: string;
  value?: string;
}

interface FlagProps {
  country: Country;
  countryName: string;
  flagUrl?: string;
  flags?: Flags;
}

export const MobileInput = (props: InputProps) => {
  const {
    id,
    name,
    control,
    placeholder,
    required: isRequired = true,
    className
  } = props

  const a = (props: FlagProps) => {
    const {country, flagUrl} = props

    const replace = flagUrl?.slice(0, - 8)
    const url = country === 'SA' ? saudiFlag : `${replace}${country}.svg`

    return <img src={url} alt={props.countryName}/>
  }
  const {t, i18n: {language}} = useTranslation()
  const requiredText = t('common.thisIsRequired')

  const {
    field: {onChange, value},
    formState: {errors},
  } = useController({
    name,
    control,
    rules: {
      required: isRequired && requiredText,
    },
  });

  return (
    <CountryFlagWrapper>
      <PhoneInput
        data-testid='phone-input'
        numberInputProps={{
          dir: 'ltr',
          style: {
            textAlign: language === 'en' ? 'left' : 'right'
          }
        }}
        onChange={onChange}
        value={value}
        id={id}
        placeholder={placeholder}
        name={name}
        className={className}
        flagComponent={a}
      />
      <ErrorMessage data-testid='phone-error' errors={errors} name={name} as={'p'}/>
    </CountryFlagWrapper>
  );
}

