import styled from 'styled-components'
import {NavLink} from 'react-router-dom'
import rtl from "styled-components-rtl";
import themeMain from '../../../theme/themeMain'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    transform: rotateZ(${props => props.theme.dir === 'rtl' ? '180deg' : '0deg'});
  }
`

export const Link = styled(NavLink)`
  color: ${themeMain.colors.black};
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  
  svg{
    padding-top: 3px;
    ${rtl`margin-right: 14px;`};
  }

  &:hover {
    text-decoration: underline;
  }
`
