import {makeAutoObservable} from 'mobx'
import api from '../api/axiosInterceptor'
import {GET_COMMITTEE_TIME_SLOTS} from '../constants/api'
import getErrorText from '../utils/getErrorText'
import moment from "moment";
import {LocationUnion} from "../app/private/jobseeker/api/calendar/types";

type CommitteeEvent = {
  id: number
  linkId: number
  eventType: string
  title: string
  description: string
  locationId: number
  startDate: string
  endDate: string
  locationType: LocationUnion
  fullyAccepted: boolean
}

type RangeType = {
  startDate: string
  endDate: string
}

interface JobSeekerCalendarStore {
  loading: boolean
  error: string
  dateRange: RangeType
  events: CommitteeEvent[]
  setLoading: (loading: boolean) => void
  setEvents: (cards: CommitteeEvent[]) => void
  setError: (error: string) => void
  setDateRange: (filters: RangeType) => void
}

const initialDateRange = {
  startDate: moment().startOf('week').format(),
  endDate: moment().endOf('week').format(),
} as RangeType

class Calendar implements JobSeekerCalendarStore {
  error: string = ''
  loading: boolean = true
  dateRange: RangeType = initialDateRange
  events: CommitteeEvent[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  setEvents = (events: CommitteeEvent[]) => {
    this.events = events
  }

  setError = (error: string) => {
    this.error = error
  }

  setDateRange = (range: RangeType): void => {
    this.dateRange = range
  }

  fetchEvents = async () => {
    this.setLoading(true)
    this.setError('')

    try {
      const response = await api.post<CommitteeEvent[]>(GET_COMMITTEE_TIME_SLOTS, this.dateRange)
      this.setEvents(response.data)

    } catch (e) {
      this.setError(getErrorText(e?.data?.messages?.[0]?.key?.value))
    } finally {
      this.setLoading(false)
    }
  }
}

const laoCalendarStore = new Calendar()
export default laoCalendarStore
