import React  from 'react';
import {Component} from './styles';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'primary' | 'secondary' | 'outline-green' | 'outline-red' | 'gold' | 'red' | "text-green";
  size?: 'big' | 'medium' | 'small';
  hoverText?: string;
};

export const Button = ({
  children,
  variant = "primary",
  size,
  hoverText = '',
  ...rest
}: ButtonProps) => {
  return (
    <Component variant={variant} size={size} {...rest}>
      {children}
      {hoverText !== '' && <span className='tooltipText'>{hoverText}</span>}
    </Component>
  );
};
