import {lazy} from "react";
import {Route, Routes} from "react-router-dom";
import {
  ACCOUNT,
  ADMIN, ADMINISTRATIVE_COMMITTEE,
  COMMITTEE,
  EMPLOYERS,
  RECEPTIONIST
} from "../../../constants/routes";
import {PrivateRoute} from "../../layouts/PrivateRoute";
import {Role} from "../../../utils/roles";

const Main = lazy(() => import('../../layouts/Main'));
const LandingMain = lazy(() => import('../../public/LandingMain'));
const Login = lazy(() => import('../../auth/Login'));
const QiwaLogin = lazy(() => import('../../auth/QiwaLogin'));
const Register = lazy(() => import('../../auth/Register'));
const EmailConfirm = lazy(() => import('../../auth/EmailConfirm'));
const EmailConfirmToken = lazy(() => import('../../auth/EmailConfirm/EmailConfirmToken'));
const ChangeEmailConfirm = lazy(() => import('../../private/jobseeker/pages/UserSettings/EmailConfirm'));
const Forgot = lazy(() => import('../../auth/Forgot'))
const ForgotConfirm = lazy(() => import('../../auth/Forgot/ForgotConfirm'));
const Terms = lazy(() => import('../../public/Terms'));
const Privacy = lazy(() => import('../../public/Privacy'));
const FAQ = lazy(() => import('../../public/FAQ'));
const Jobseeker = lazy(() => import('../../private/jobseeker'));
const Employer = lazy(() => import('../../private/employer'));
const Admin = lazy(() => import('../../private/admin'));
const Committee = lazy(() => import('../../private/committee'));
const Receptionist = lazy(() => import('../../private/receptionist'));
const AdminCommittee = lazy(() => import('../../private/admin_committee'));


const AppRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<Main/>}>
        <Route index element={<LandingMain/>}/>
        {/*<Route path='employer' element={<LandingEmployer/>}/>*/}
        <Route path='login' element={<Login/>}/>
        <Route path='qiwa/login' element={<QiwaLogin/>}/>
        <Route path='register' element={<Register/>}/>
        <Route path='email-confirm' element={<EmailConfirm/>}/>
        <Route
          path='email-confirm/:token'
          element={<EmailConfirmToken/>}
        />
        <Route
          path='change-email/:token'
          element={<ChangeEmailConfirm/>}
        />
        <Route path='forgot-password' element={<Forgot/>}/>
        <Route
          path='reset'
          element={<ForgotConfirm/>}
        />
        <Route path='terms' element={<Terms/>}/>
        <Route path='privacy' element={<Privacy/>}/>
        <Route path='faq' element={<FAQ/>}/>
      </Route>
      <Route
        path={ACCOUNT}
        element={
          <PrivateRoute roles={Role.jobSeeker}>
            <Jobseeker/>
          </PrivateRoute>
        }
      />
      <Route
        path={EMPLOYERS}
        element={
          <PrivateRoute roles={Role.employer}>
            <Employer/>
          </PrivateRoute>
        }
      />
      <Route
        path={ADMIN}
        element={
          <PrivateRoute roles={Role.admin}>
            <Admin/>
          </PrivateRoute>
        }
      />
      <Route
        path={COMMITTEE}
        element={
          <PrivateRoute roles={Role.committee}>
            <Committee/>
          </PrivateRoute>
        }
      />
      <Route
        path={RECEPTIONIST}
        element={
          <PrivateRoute roles={Role.receptionist}>
            <Receptionist/>
          </PrivateRoute>
        }
      />
      <Route
        path={ADMINISTRATIVE_COMMITTEE}
        element={
          <PrivateRoute roles={Role.admin_committee}>
            <AdminCommittee />
          </PrivateRoute>
        }
      />
      <Route
        path='*'
        element={
          <>
            <div>404</div>
          </>
        }
      />
    </Routes>
  )
}

export default AppRouter;