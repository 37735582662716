import styled from 'styled-components';
import themeMain from '../../../theme/themeMain';
import rtl from "styled-components-rtl";

type BoxProps = {
  isBanned?: boolean;
  isCompleted?: boolean;
  emptyFetch?: boolean
  statusError?: boolean
};

export const SkillBoxComponent = styled.div<BoxProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 30px;
  margin-bottom: 16px;
  border-radius: 4px;
  box-shadow: 0 3px 16px 0 rgba(0, 0, 0, 0.16);
  background-color: ${themeMain.colors.white};
  outline: ${({isBanned, isCompleted, emptyFetch, statusError}) => (isBanned === true)
                ? `2px solid ${themeMain.colors.red}` 
                : (isCompleted === false || emptyFetch === false || statusError)
                ? `2px solid #F8AF70` 
                : 'none'
            };

  i[class*='ban'] {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 22px;
    font-weight: bold;
    color: ${themeMain.colors.red};
  }
  
  span.completeness {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 3px dashed ${themeMain.colors.orange};
    border-left-style : none;
    background: linear-gradient(to right, ${themeMain.colors.orange} 50%, white 50%);
  }
`;

export const SkillHeaderComponent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${themeMain.colors.gray.light};
  margin-bottom: 20px;

  h2 {
    margin-bottom: 20px;
    font-size: 20px;
  }

  i {
    color: ${themeMain.colors.green};
    font-size: 22px;
    cursor: pointer;
  }
`;

export const SkillContentComponent = styled.div``;

export const SkillTooltipComponent = styled.div`
  margin-top: 10px;
  padding: 10px;
  border: 1px dotted ${themeMain.colors.gray.light};

  p {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
  }

  i {
    font-size: 16px;
    color: ${themeMain.colors.green};
    margin-top: 3px;
    ${rtl`margin-right: 5px;`};
  }
`;

export const SkillButtonsComponent = styled.div`
  padding-top: 4px;

  i {
    & + i {
      ${rtl`margin-left: 16px;`};
    }
  }
`;

export const SkillElementComponent = styled.div`
  display: flex;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${themeMain.colors.gray.light};

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
  }

  i {
    &[class*='mi-school'],
    &[class*='mi-office'] {
      color: ${themeMain.colors.gray.light};
      font-size: 44px;
      padding-top: 5px;
      ${rtl`margin-right: 20px;`};
    }

    &:last-child {
      font-size: 22px;
      color: ${themeMain.colors.green};
      ${rtl`margin-left: auto;`};
      cursor: pointer;
    }
  }
`;

export const SkillBodyComponent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  h3 {
    margin-bottom: 3px;
  }

  p {
    margin-bottom: 0;

    @media (min-width: 560px) {
      word-break: break-word;
    }
  }

  .rc-slider-mark {
    span {
      &:first-child {
        ${rtl`transform: translateX(-5%) !important;`};
      }

      &:last-child {
        ${rtl`transform: translateX(-95%) !important;`};
      }
    }

    .rc-slider-mark-text {
      cursor: auto;
    }
  }

  .rc-slider-handle {
    cursor: auto !important;
    box-shadow: none !important;
    border-color: #96dbfa !important;
  }
`;

export const SkillRowComponent = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 400px) {
    margin-bottom: 20px;
  }

  p {
    &:first-child {
      font-weight: bold;
    }
  }
`;

export const SkillColComponent = styled.div`
  flex: 0 0 100%;
  margin-bottom: 10px;

  @media (min-width: 400px) {
    flex: 0 0 33.333%;
    margin-bottom: 0;
  }

  p {
    display: flex;
    align-items: center;
  }

  span {
    font-size: 10px;
    font-weight: bold;
    color: ${themeMain.colors.green};
  }

  span.phone {
    font-size: 14px;
    font-weight: 400;
    color: #4A4A4A;
  }

  i {
    font-size: 10px;
    color: ${themeMain.colors.green};
    ${rtl`margin: 0 3px 0 10px;`};
  }
`;

export const SkillFieldDynamicComponent = styled.div`
  display: flex;

  fieldset {
    flex-grow: 1;
    
    >div{
      width: 100%;
      max-width: none;

      @media (min-width: 480px) {
        max-width: calc(50% - 8px);
      }
    }
  }

  i[class*='bin'] {
    color: ${themeMain.colors.red};
    font-size: 30px;
    ${rtl`margin: 32px 0 0 16px;`};
    cursor: pointer;
  }
`;
