import {makeAutoObservable} from 'mobx'
import api from '../api/axiosInterceptor'
import {GET_PROFILES} from '../constants/api'
import getErrorText from '../utils/getErrorText'

export type Card = {
  userId: number
  email: string | null
  firstName: string
  lastName: string
  jobTitle: string | null
  nationality: string | null
  location: string | null
  yearsOfExperience: number | null
  completenessCriteria: number | null
  banReason: string | null
  lastLoginDate: string | null
  createdAt: string | null
  status: 'ACTIVE' | 'BANNED'
  completed: boolean
  completeness: number | null
  avatarFileName: string | null
}

type Filters = {
  searchText?: string
  yearsOfExperienceCriterias?: string[]
  educationCriterias?: string[]
  fieldOfStudies?: string[]
  nationalities?: string[]
  industries?: string[]
  genderCriteria?: string
  completenessCriteria?: string
  userIdSearchValue?: number
  firstNameSearchValue?: string
  lastNameSearchValue?: string
  emailSearchValue?: string
  profileStatusCriteria?: string
  sort?: string
  order?: 'ASC' | 'DESC'
  orders?: {
    fieldName: string,
    order: 'ASC' | 'DESC'
  }[]
  creationStartTime?: string
  creationEndTime?: string
  loginStartTime?: string
  loginEndTime?: string
  page?: number
  size?: number
}

interface HrPoolStore {
  total: number
  loading: boolean
  error: string
  pageAmount: number
  filters: Filters
  cards: Card[]
  selectedResumeId: number | null
  setLoading: (loading: boolean) => void
  setCards: (cards: Card[]) => void
  setTotal: (size: number, total: number) => void
  setError: (error: string) => void
  setFilters: (filters: { size: number; page: number }) => void
  setSelectedResumeId?: (id: number | null) => void
}

type FetchCardsResponse = {
  total: number
  pageNumber: number
  content: Card[]
}

const initialFilters = {
  size: 30,
  searchText: '',
  genderCriteria: '',
  educationCriterias: [],
  fieldOfStudies: [],
  industries: [],
  nationalities: [],
  yearsOfExperienceCriterias: [],
  banCriteria: '',
  orders: []
} as Filters

class HrPool implements HrPoolStore {
  total: number = 1
  pageAmount = 1
  error: string = ''
  loading: boolean = true
  filters: Filters = initialFilters
  cards: Card[] = []
  selectedResumeId: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  setCards = (cards: Card[]) => {
    this.cards = cards
  }

  setError = (error: string) => {
    this.error = error
  }

  setFilters = (filters: Filters): void => {
    const {sort, order, creationStartTime, creationEndTime, loginStartTime, loginEndTime,...allFilters} = filters
    const cStartDate =  (''+creationStartTime)?.split('GMT')[0]
    const cEndDate =  (''+creationEndTime)?.split('GMT')[0]
    const llStartDate =  (''+loginStartTime)?.split('GMT')[0]
    const llEndDate =  (''+loginEndTime)?.split('GMT')[0]

    this.filters = {
      ...initialFilters,
      ...(order && sort && {
        orders: [{
          fieldName: sort,
          order: order
        }]
      }),
      ...(creationStartTime && {
        creationTimeRange: {
          startTime: cStartDate,
          ...(creationEndTime ? {
            endTime: cEndDate
          }: {endTime: new Date()})
        }
      }),
      ...(loginStartTime && {
        lastLoginTimeRange: {
          startTime: llStartDate,
          ...(loginEndTime ? {
            endTime: llEndDate
          }: {endTime: new Date()})
        }
      }),
      ...allFilters
    }
  }

  setTotal = (size: number, total: number) => {
    this.total = Math.ceil(total / size)
  }

  fetchCards = async () => {
    if (this.filters['searchText'] && this.filters['searchText'].length < 3) return;
    this.filters['page'] = this.filters['page'] ? +this.filters['page'] - 1 : 0;

    this.setLoading(true)
    this.setError('')
    try {
      const mappedFilters = Object.keys(this.filters).reduce((acc, curr) => {
        const key = curr as keyof Filters
        if (Array.isArray(this.filters[key])) {
          if ((this.filters[key] as []).length) {
            return {
              ...acc,
              [key]: this.filters[key]
            }
          }
        }
        if (!Array.isArray(this.filters[key]) && this.filters[key]) {
          return {
            ...acc,
            [key]: this.filters[key]
          }
        }
        return acc
      }, {} as Filters)
      const response = await api.post<FetchCardsResponse>(GET_PROFILES, mappedFilters)
      this.setCards(response.data.content)
      this.setTotal(this.filters['size'] ?? 30, response.data.total)
    } catch (e) {
      this.setError(getErrorText(e?.data?.messages?.[0]?.key?.value))
    } finally {
      this.setLoading(false)
    }
  }

  setSelectedResumeId = (id: number | null) => {
    this.selectedResumeId = id
  }
}

const hrPoolStore = new HrPool()
export default hrPoolStore
