import styled, {css} from 'styled-components';
import {devices} from '../../../theme/devices';
import themeMain from '../../../theme/themeMain';
import rtl from "styled-components-rtl";
import {Button} from "../../components";

type StyledComponentProps = {
  open: boolean;
  isRtl?: boolean;
  isLanding?: boolean
};

type HeaderProps = {
  isLanding: boolean
}
type NotificationIconWrapperProps = {
  isLanding: boolean
}


export const StyledBurger = styled.div<StyledComponentProps>`
  width: 32px;
  height: 32px;
  z-index: 20;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  align-self: center;

  @media ${devices.laptop} {
    display: none;
  }

  div {
    width: 32px;
    height: 4px;
    margin: 3px 0;
    background-color: ${({open}) => (open ? `#ccc !important` : '#333')};
    border-radius: 10px;
    transform-origin: 1px;
    transition: transform 0.3s linear;

    &:nth-child(1) {
      transform: ${({open}) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }

    &:nth-child(2) {
      transform: ${({open}) => (open ? 'translateX(100%)' : 'translateX(0)')};
      opacity: ${({open}) => (open ? 0 : 1)};
    }

    &:nth-child(3) {
      transform: ${({open}) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }
  }
`;

export const Ul = styled.div<StyledComponentProps>`
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
  justify-content: center;
  align-items: flex-start;
  position: fixed;
  top: 0;
  height: 100vh;
  bottom: 0;
  overflow-y: auto;
  width: ${({open}) => (open ? '100%' : 0)};
  opacity: ${({open}) => (open ? 1 : 0)};
  transform: ${({
                  open,
                  isRtl
                }) => (open ? `${isRtl ? 'translateX(100%)' : 'translateX(-100%)'}` : 'translateX(0)')};
  transition: all 0.3s ease-in-out;
  transition-property: transform, opacity, width;
  z-index: 10;
  background-color: ${({open}) => open ? `${themeMain.colors.white} !important` : 'transparent'};
  padding-top: 90px;
  right: 0;
  left: 100%;

  ${({isRtl}) => isRtl && css`
    right: 100% !important;
    left: 0 !important;
  `} @media screen and ${devices.laptop} {
    position: static;
    flex-flow: row nowrap;
    align-items: stretch;
    overflow-y: unset;
    transform: none;
    background-color: ${themeMain.colors.white};
    opacity: 1;
    transition: none;
    height: auto;
    padding-top: 0;
  } nav {
    display: flex;
    align-items: center;

    ul {
      display: flex;
      flex-direction: column;
      list-style-type: none;
      margin: 0;
      ${rtl`padding-left: 0;`};

      @media screen and ${devices.laptop} {
        flex-direction: row;
        ${rtl`padding-left: 75px;`};
      }

      li {
        ${rtl`margin-right: 10px;`};

        a {
          margin-bottom: 10px;

          @media screen and ${devices.laptop} {
            margin-bottom: 0;
          }
        }

      }
    }
  }
`;

export const HeaderComponent = styled.header<HeaderProps>`
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  z-index: 10;


  ${({isLanding}) =>
          isLanding &&
          css`
            position: absolute;
            top: 0;
            width: 100%;
            box-shadow: none;

            ${StyledBurger} {
              div {
                background-color: #fff;
              }
            }

            ${Ul} {
              background: transparent;
            }
          `}
  .logo {
    position: relative;
    z-index: 11;
  }
`;


export const NotificationWrapper = styled.div`
  ${rtl`margin-right: 20px`};
  
  @media screen and (max-width: 1024px) {
    ${rtl`margin-left: auto;`};
    ${rtl`margin-right: 20px;`};
  }
  
  &:empty{
    display: none;
  }
`;

export const NotificationIconWrapper = styled.div<NotificationIconWrapperProps>`
  position: relative;
  cursor: pointer;
  transition: .5s transform;

  &:hover {
    transform: rotateZ(30deg);
  }

  &.open {
    transform: rotateZ(30deg);
  }
`;

export const NotificationRedDot = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 7px;
  height: 7px;
  background: ${themeMain.colors.red};
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid ${themeMain.colors.white};
`;

export const ChangeLangButton = styled.button<{ isLanding: boolean }>`
  background: transparent;
  outline: none;
  appearance: none;
  border: none;
  cursor: pointer;
  font-weight: 400;
  color: ${themeMain.colors.black};
  @media screen and ${devices.laptop} {
    color: ${props => props.isLanding ? themeMain.colors.white : themeMain.colors.gray.dark};
  }
  &:hover {
    text-decoration: underline;
  }
`;

export const AvatarDropdownButton = styled(Button)`
  justify-content: flex-start;
  padding: 0;
  padding-inline-start: 16px;
  background-color: transparent;
  color: ${themeMain.colors.black};
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 12px;
  
  &:hover,
  &:focus{
    background-color: #DEEBFF;
  }
  
  &:last-child{
    margin-bottom: 0;
  }
  
  span{
    margin-inline-end: 12px;
  }
`

export const AvatarDropdownIcon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  background-color: ${themeMain.colors.whiteSmoke};
`