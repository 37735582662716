import React from 'react';
import {Component, TextComponent} from './styles';

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4'
export type TextVariant = 'big' | 'regular' | 'regular-l' | 'regular-bold'

type TypographyProps = {
  children: React.ReactNode;
  variant: HeadingVariant;
  style?: React.CSSProperties;
};

const defaultProps: TypographyProps = {
  children: null,
  variant: 'h1',
};

export const Heading = ({
  children,
  variant,
  ...rest
}: TypographyProps) => {
  return (
    <Component {...rest} as={variant} variant={variant}>
      {children}
    </Component>
  );
};

Heading.defaultProps = defaultProps;

type TextProps = {
  children: React.ReactNode;
  variant?: TextVariant;
  color?: string;
  style?: React.CSSProperties;
  className?: string
};

const defaultTextProps: TextProps = {
  children: null,
  variant: 'regular',
};

export const Text = ({children, variant= 'regular', color, ...rest}: TextProps) => {
  return (
    <TextComponent {...rest} variant={variant} color={color}>
      {children}
    </TextComponent>
  );
};

Text.defaultProps = defaultTextProps;
