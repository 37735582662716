import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

type DirectionLang = 'en' | 'ar'

export const useDirection = () => {
  const [lang, setLang] = useState<DirectionLang>(localStorage.getItem('language') as DirectionLang || 'en')

  const {i18n: {changeLanguage}} = useTranslation()

  useEffect(() => {
    changeLanguage(lang)
    document.body.dir = lang === 'ar' ? 'rtl' : 'ltr'
    localStorage.setItem('language', lang === 'ar' ? 'ar' : 'en')
  }, [changeLanguage, lang]);

  return {lang, setLang}
}