import styled from 'styled-components';
import {NavLink} from 'react-router-dom';
import themeMain from '../../../theme/themeMain';
import {fonts} from '../../../theme/fonts';
import rtl from "styled-components-rtl";
import {devices} from "../../../theme/devices";

export const ComponentWrapper = styled(NavLink)`
  text-decoration: none;
  display: inline-flex;
  padding: 0;
  ${rtl`margin-right: 16px;`};

  &.active {
    position: relative;

    &:before {
      display: block;
      position: absolute;
      content: '';
      background-color: ${themeMain.colors.green};
      height: 2px;
      width: 100%;
      top: 18px;
      ${rtl`left: 0`};

      @media screen and ${devices.laptop} {
        top: 41px;
      }
    }
  }
`;

export const Component = styled.span`
  font: 500 14px/16px ${fonts.openSans};
  color: ${themeMain.colors.gray.dark};
`;
