import React, {InputHTMLAttributes, useEffect, useState} from 'react';
import {
  Component,
  InputWrapper,
  FormItemComponent,
  InputHintComponent,
  InputVerifiedComponent,
  InputNumber,
} from './styles';
import {Icon} from "../Icon";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  children?: React.ReactNode;
  label?: string;
  testId?: string
}

interface FormItemProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  dir?: string;
  mandatory?: boolean;
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {label, tabIndex, testId, id, children, ...inputProps},
    ref
  ) => {
    return (
      <Component {...inputProps}>
        {label && <label htmlFor={id}>{label}</label>}
        <InputWrapper>
          <input
            {...inputProps}
            tabIndex={tabIndex}
            id={id}
            ref={ref}
            data-testid={testId}
          />
          {children}
        </InputWrapper>
      </Component>
    );
  }
);

export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const [passwordShown, setPasswordShown] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordShown((prevState) => !prevState);
  }

  return (
    <Input
      {...props}
      ref={ref}
      type={passwordShown ? 'text' : 'password'}
    >
      <Icon
        testId='show-pass-btn'
        name='eye'
        onClick={togglePasswordVisibility}
        className='clickable-icon'
      />
    </Input>
  )
})

type InputNumberProps = InputHTMLAttributes<HTMLInputElement> & {
  value?: number
}

const EVENT_KEYS = ['e', 'E', '+', '-']
export const NumberInput = React.forwardRef<HTMLInputElement, InputNumberProps>((props, ref) => {
  const {onChange, value = '', min = 0, max, step = 1, ...rest} = props
  const [stateValue, setStateValue] = useState(value)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    EVENT_KEYS.includes(e.key) && e.preventDefault()
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
    if (!e.target.value) return setStateValue('')
    if (e.target.value < min) return setStateValue(Number(min))
    if (max && e.target.value > max) return setStateValue(Number(max))
    setStateValue(Number(e.target.value.replace(Number.isInteger(step) ?
        /\D+/g : /\^[-,0-9]+$/g,
      '')))
  }

  useEffect(() => {
    if ((stateValue === '' && value !== '') || (Number(value) !== Number(stateValue))) {
      if (value < min) {
        return setStateValue(Number(min))
      }
      if (max && value > max) {
        return setStateValue(Number(max))
      }
      setStateValue(Number(value))
    }
  }, [value])

  return <InputNumber {...rest} type="number" ref={ref} onKeyDown={handleKeyDown} onChange={handleChange}
                      value={stateValue}/>
})

export const FormItem = ({children, dir, ...props}: FormItemProps) => {
  return <FormItemComponent dir={dir} {...props}>{children}</FormItemComponent>;
};

export const InputHint = ({children}: FormItemProps) => {
  return <InputHintComponent>{children}</InputHintComponent>;
};

export const InputVerified = ({children}: FormItemProps) => {
  return <InputVerifiedComponent>{children}</InputVerifiedComponent>;
};
