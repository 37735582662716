import React, {useState} from 'react';
import {Component, InputWrapper} from './styles';

export interface TextAreaProps {
  name?: string;
  label?: string;
  id?: string;
  value?: string;
  defaultValue?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  rows?: number;
  maxLength?: number | false;
  testId?: string;
  disabled?: boolean;
  style?: React.CSSProperties;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      maxLength = 500,
      id,
      label,
      value,
      defaultValue,
      placeholder,
      required,
      testId,
      ...inputProps
    },
    ref
  ) => {
    const [content, setContent] = useState((value || defaultValue)?.slice(0, maxLength || 0));
    const setFormattedContent = (text: string) => {
      const finalText = text.slice(0, maxLength || 500)
      setContent(finalText);
    }

    return (
      <Component {...inputProps}>
        {label && <label htmlFor={id}>{label}</label>}
        <InputWrapper>
          <textarea
            {...inputProps}
            ref={ref}
            rows={4}
            id={id}
            maxLength={maxLength || 500}
            value={value}
            placeholder={placeholder}
            required={required}
            onChange={(event) => setFormattedContent(event.target.value)}
            data-testid={testId}
          />
          {Number(maxLength) > 0 && <span>{content?.length ?? 0}/{maxLength}</span>}
        </InputWrapper>
      </Component>
    );
  }
);
