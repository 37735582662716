import React, {useEffect} from 'react'
import {LoaderWrapper} from './styles'

type Props = {
  width?: number | string
  height?: number | string
  style?: React.CSSProperties
  loaderSize?: 'small' | 'normal'
}

export const Loader = (props: Props) => {
  const {width, height, style, loaderSize = 'small'} = props

  useEffect(() => {
    return () => {
      document.querySelector('.loader')?.classList.add('loaded')
      document.querySelector('body')?.classList.remove('loading')
    }
  }, [])

  return (
    <LoaderWrapper style={{width, height, ...style}} loaderSize={loaderSize} data-testid="loader">
      <div/>
      <div/>
      <div/>
      <div/>
    </LoaderWrapper>
  );
};