import {useEffect, useState} from 'react'

export const useScreenSize = (size: number) => {
  const handleCheckWidth = () => {
    setIsEqual(window.innerWidth <= size)
  }
  const [isEqual, setIsEqual] = useState(document.body.clientWidth <= size)

  useEffect(() => {
    window.addEventListener('resize', handleCheckWidth)
    return () => window.removeEventListener('resize', handleCheckWidth)
  }, [])

  return isEqual
}
