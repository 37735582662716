import styled from 'styled-components'
import themeMain from '../../../theme/themeMain'
import rtl from "styled-components-rtl";

export const TooltipWrapper = styled.div`
  margin-top: 10px;
  padding: 10px;
  border: 1px dotted ${themeMain.colors.gray.light};

  p {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0;
    color: ${themeMain.colors.gray.medium};
  }

  i {
    font-size: 16px;
    color: ${themeMain.colors.green};
    margin-top: 3px;
    ${ rtl`margin-right: 5px;` };
  }
`;
