import styled from 'styled-components'
import themeMain  from '../../../theme/themeMain'
import rtl from "styled-components-rtl";

export const ProgressBarTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 16px;
  margin-bottom: 5px;
`

export const Title = styled.p`
  font-size: 14px;
  font-weight: bold;
  color: ${themeMain.colors.gray.dark};
  margin: 0;
`

export const ProgressBarWrapper = styled.div`
  position: relative;
`

export const ProgressBarLine = styled.div`
  width: 100%;
  height: 14px;
  border-radius: 20px;
  background: ${themeMain.colors.gray.light};
  overflow: hidden;
`

export const ProgressBarFilling = styled.div`
  height: 100%;
  background: ${({color}) => color};
  transition: .3s width;
  border-radius: 20px;
`

export const ProgressBarBtn = styled.div`
  position: absolute;
  top: -3px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: ${themeMain.colors.white};
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: 1px solid ${themeMain.colors.gray.light};
  border-radius: 50%;

  ${ rtl`right: calc(20% - 1px)` };
  
  & i {
    color: ${themeMain.colors.gray.dark};
    font-size: 11px;
    font-weight: bold;
  }
  
  &.unlocked {
    border-color: ${themeMain.colors.green};
    
    & i {
      color: ${themeMain.colors.green};
    }
  }
`
