import {makeAutoObservable, toJS} from 'mobx';
import api from '../api/axiosInterceptor';
import {
  DELETE_NOTIFICATION,
  GET_NOTIFICATIONS,
  GET_PROFILE_COMPLETENESS,
  POST_WIZZARD_INFO,
  PROFILE_AVATAR,
  PROFILE_INFO,
  READ_ALL,
  READ_NOTIFICATION
} from '../constants/api';
import {AxiosResponse} from 'axios'
import {ResponseWithPagination} from '../types'
import {
  NotificationData
} from "../app/private/jobseeker/components/Notifications/Notification";

export const mandatoryPercents = 80;

export type TSkill = {
  id: number;
  name: string;
  level: string;
};

export type TLanguage = {
  id: number;
  name: string;
  level: string;
};

export type TWorkExperience = {
  jobTitle: string;
  company: string;
  industry: string;
  startYear: number;
  startMonth: number;
  eduMonth?: number;
  eduYear?: number;
  active?: boolean;
  location: string;
  description: string;
};

export type TCertificate = {
  userId: number;
  name: string;
  issueMonth: number;
  issueYear: number;
  expireMonth: number;
  expireYear: number;
};

export type TEducation = {
  userId: number;
  degree: string;
  fieldOfStudy: string;
  university: string;
  location: string;
  graduationYear: number;
  graduationMonth: number;
  description: string;
  grade: {
    system: string;
    score: number;
  };
};

export type TUser = {
  userId: number;
  firstName: string;
  lastName: string;
  email: string;
  dateOfBirth: string;
  gender: string;
  nationality: string;
  passportNumber: string;
  location: string;
  phoneNumber: string;
  phoneNumberVerified: boolean;
  avatar: string;
  workExperience: TWorkExperience[];
  education: [];
  skills: TSkill[];
  certificates: TCertificate[];
  languages: TLanguage[];
  role: string;
  nationalId?: number | string
};

type FetchProfileCompletenessResponse = {
  userId: number
  completeness: number
}

type FetchWizzardInfoResponse = { userId: number, wizardPassed: boolean }

export type WizzardGuideStep = 1 | 2 | 3 | 4

export type NotificationType =
  | 'JA_SHORTLISTED'
  | 'JA_APPLIED'
  | 'JA_INTERVIEW'
  | 'OFFER_RECEIVED'
  | 'OFFER_EXPIRED'
  | 'JO_EXPIRED_CLOSED'
  | 'UNBAN_REQUEST_ACCEPTED'
  | 'UNBAN_REQUEST_REJECTED'
  | 'INFO'
  | 'PERMANENT_BAN'
  | 'USER_BANNED'
  | 'USER_UNBANNED'
  | 'INTERVIEW_TIME_SLOT'
  | 'ANNOUNCEMENT'

export type Notification = NotificationData & {
  id: number
  createdAt: string
  type: NotificationType
  read: boolean
  isSending?: boolean
}

interface UserStore {
  showWizzardGuide: boolean;
  wizzardGuideStep: WizzardGuideStep;
  user: TUser;
  resumeFilled: null | number;
  loading: boolean;
  notificationsLoading: boolean
  notifications: Notification[];
  hasNewNotification: boolean;
  isNotificationOpen: boolean;
  uploadAvatarLoading: boolean;
  uploadAvatarError: string;
  notificationPagination: {
    page: number
    totalPages: number
    size: number
  }
}

const initialUser = {
  userId: 0,
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: '',
  gender: '',
  nationality: '',
  passportNumber: '',
  location: '',
  phoneNumber: '',
  phoneNumberVerified: false,
  avatar: '',
  skills: [],
  languages: [],
  workExperience: [],
  certificates: [],
  education: [],
  role: '',
} as TUser;

class User implements UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  wizzardGuideStep: WizzardGuideStep = 1;
  showWizzardGuide = false;
  notificationsLoading = true
  notificationPagination = {
    page: 0,
    totalPages: 0,
    size: 20,
  }
  notifications: Notification[] = []
  user = initialUser;
  resumeFilled: null | number = null
  loading = true;
  hasNewNotification = false;
  isNotificationOpen = false;
  uploadAvatarLoading = false
  uploadAvatarError = ''

  setUploadAvatarError = (error: string) => {
    this.uploadAvatarError = error
  }

  setUploadAvatarLoading = (loading: boolean) => {
    this.uploadAvatarLoading = loading
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  fetchWizzardInfo = async () => {
    this.setWizzardGuideStep(1)
    try {
      const {data} = await api.post<FetchWizzardInfoResponse>(POST_WIZZARD_INFO)
      this.setShowWizzardGuide(!data.wizardPassed)
    } catch (e) {
      console.log(e)
    }
  }

  setShowWizzardGuide = (show: boolean) => {
    this.showWizzardGuide = show
  }

  setWizzardGuideStep = (step: WizzardGuideStep) => {
    this.wizzardGuideStep = step
  }

  setNotificationsLoading = (loading: boolean) => {
    this.notificationsLoading = loading
  }

  setNotificationPagination = (totalPages: number, page: number, size: number = 20) => {
    this.notificationPagination = {
      totalPages,
      page,
      size
    }
  }

  setNotifications = (notifications: Notification[]) => {
    this.notifications = notifications
  }

  setHasNewNotification = (bool: boolean) => {
    this.hasNewNotification = bool
  }

  setIsNotificationOpen = (bool: boolean) => {
    this.isNotificationOpen = bool
  }

  readNotification = async (id: number) => {
    this.setNotifications(this.notifications.map((item) => {
      if (item.id !== id) return item
      return {
        ...item,
        isSending: true
      }
    }))
    try {
      await api.put(READ_NOTIFICATION(id))
      this.setNotifications(this.notifications.map((item) => {
        if (item.id !== id) return item
        return {
          ...item,
          read: true,
          isSending: false
        }
      }))
      this.setHasNewNotification(this.notifications.some((item) => !item.read))
    } catch (e) {
      this.setNotifications(this.notifications.map((item) => {
          if (item.id !== id) return item
          return {
            ...item,
            isSending: false
          }
        })
      )
    }
  }

  readAllNotifications = async () => {
    try {
      await api.put(READ_ALL)
      this.setNotifications(this.notifications.map((item) => ({
        ...item,
        read: true
      })))
      this.setHasNewNotification(false)
    } catch (e) {
      console.log(e)
    }
  }

  deleteNotification = async (id: number) => {
    try {
      await api.delete(DELETE_NOTIFICATION(id))
      this.setNotifications(this.notifications.filter((item) => item.id !== id))
      this.setHasNewNotification(this.notifications.some((item) => !item.read))
    } catch (e) {
      console.log(e)
    }
  }

  fetchNotifications = async (page: number = 0) => {
    this.setNotificationsLoading(true)
    try {
      const {data} = await api.post<ResponseWithPagination<Notification[]>, AxiosResponse<ResponseWithPagination<Notification[]>>, {
        page: number,
        size: number
      }>(GET_NOTIFICATIONS, {
        page: page || 0,
        size: this.notificationPagination.size
      })
      const concatArr = page === 0 ? [...data.content, ...toJS(this.notifications)] : [...toJS(this.notifications), ...data.content]
      const uniqArr: Notification[] = [...new Map(concatArr.map((item) => [item.id, item])).values()]
      this.setNotificationPagination(data.totalPages, page || this.notificationPagination.page)
      this.setNotifications(uniqArr)
      this.setHasNewNotification(uniqArr.some((item) => !item.read))
    } catch (e) {
      console.log(e)
    } finally {
      this.setNotificationsLoading(false)
    }
  }

  fetchProfileCompleteness = async () => {
    try {
      const response = await api.get<FetchProfileCompletenessResponse>(GET_PROFILE_COMPLETENESS)
      this.setResumeFilled(response.data.completeness)
    } catch (e) {
      console.log('err', e);
    }
  }

  fetchUser = async () => {
    try {
      const response = await api.get(PROFILE_INFO);
      this.setUser({
        ...this.user,
        ...response.data,
      });

    } catch (e) {
      console.log('err', e);
    } finally {
      this.setLoading(false)
    }
  };

  fetchAvatar = async () => {
    try {
      const response = await api.get('profile/v2/avatar/full');
      this.setUser({
        ...this.user,
        avatar: response.data.image,
      });
    } catch (e) {
      console.log(e);
    }
  };

  uploadAvatar = (file: FormData) => {
    this.setUploadAvatarLoading(true)
    return new Promise((resolve, reject) => {
      api.put(PROFILE_AVATAR, file, {
        headers: {
          'Content-Type': 'multipart/form-data; ',
        }
      }).then((response) => {
        this.setUser({
          ...this.user,
          avatar: response.data.image,
        });
        resolve(response)
      }).catch(e => {
        this.setUploadAvatarError(e?.data?.messages[0]?.key?.value || 'fail.input.generic')
        reject(e)
      }).finally(() => {
        this.setUploadAvatarLoading(false)
      })
    })
  };

  deleteAvatar = () => {
    this.setUploadAvatarLoading(true)
    return new Promise((resolve, reject) => {
      api.delete(PROFILE_AVATAR).then((response) => {
        this.setUser({
          ...this.user,
          avatar: response.data.image,
        });
        resolve(response)
      }).catch(e => {
        this.setUploadAvatarError(e?.data?.messages[0]?.key?.value || 'fail.input.generic')
        reject(e)
      }).finally(() => {
        this.setUploadAvatarLoading(false)
      })
    })
  }

  setResumeFilled = (resumeFilled: null | number) => {
    this.resumeFilled = resumeFilled
  }

  setUser = (user: TUser) => {
    this.user = {
      ...this.user,
      ...user,
    };
  };

  resetUser = () => {
    this.user = initialUser;
  };
}

const userStore = new User();
export default userStore;
