import React from "react";
import {ReactComponent as logo} from '../../../assets/svg/takamol-logo.svg';
import {ReactComponent as saudiLogo} from '../../../assets/svg/logo.svg';
import {ReactComponent as instagram} from '../../../assets/svg/instagram.svg';
import {ReactComponent as twitter} from '../../../assets/svg/twitter.svg';
import {ReactComponent as linkedin} from '../../../assets/svg/linkedin.svg';
import {ReactComponent as facebook} from '../../../assets/svg/facebook-f.svg';
import {ReactComponent as eye} from '../../../assets/svg/eye.svg';
import {ReactComponent as qiwaLogo} from '../../../assets/svg/qiwa-logo.svg';
import {ReactComponent as whyWork1} from '../../../assets/svg/whyWork1.svg';
import {ReactComponent as whyWork2} from '../../../assets/svg/whyWork2.svg';
import {ReactComponent as whyWork3} from '../../../assets/svg/whyWork3.svg';
import {ReactComponent as whyWork4} from '../../../assets/svg/whyWork4.svg';
import {
  ReactComponent as getStarted1
} from '../../../assets/svg/getStarted1.svg';
import {
  ReactComponent as getStarted2
} from '../../../assets/svg/getStarted2.svg';
import {
  ReactComponent as getStarted3
} from '../../../assets/svg/getStarted3.svg';
import {
  ReactComponent as getStarted4
} from '../../../assets/svg/getStarted4.svg';
import {ReactComponent as applied} from '../../../assets/svg/applied.svg';
import {ReactComponent as saved} from '../../../assets/svg/saved.svg';
import {ReactComponent as done} from '../../../assets/svg/done.svg';
import {
  ReactComponent as shortlisted
} from '../../../assets/svg/shortlisted.svg';
import {ReactComponent as interview} from '../../../assets/svg/interview.svg';
import {ReactComponent as offer} from '../../../assets/svg/offer.svg';
import {ReactComponent as login} from '../../../assets/svg/login.svg';
import {ReactComponent as star} from '../../../assets/svg/star.svg';
import {ReactComponent as users} from '../../../assets/svg/users.svg';
import {ReactComponent as reload} from '../../../assets/svg/reload.svg';
import {ReactComponent as block} from '../../../assets/svg/block.svg';
import {ReactComponent as wifi} from '../../../assets/svg/wifi.svg';
import {ReactComponent as wifiOff} from '../../../assets/svg/wifi-off.svg';
import {ReactComponent as business} from '../../../assets/svg/business.svg';
import {ReactComponent as pdf} from '../../../assets/svg/pdf.svg';
import {ReactComponent as info} from '../../../assets/svg/info.svg';
import {ReactComponent as time} from '../../../assets/svg/time.svg';
import {ReactComponent as location} from '../../../assets/svg/location.svg';
import {ReactComponent as calendar} from '../../../assets/svg/calendar2.svg';
import {ReactComponent as settings} from '../../../assets/svg/settings.svg';
import {ReactComponent as logout} from '../../../assets/svg/logout.svg';
import {ReactComponent as logout2} from '../../../assets/svg/logout2.svg';
import {ReactComponent as chart} from '../../../assets/svg/chart.svg';
import {ReactComponent as shareArrow} from '../../../assets/svg/shareArrow.svg';
import {ReactComponent as dots} from '../../../assets/svg/dots.svg';
import {ReactComponent as marker} from '../../../assets/svg/marker.svg';
import {ReactComponent as posted} from '../../../assets/svg/posted.svg';
import {ReactComponent as employment} from '../../../assets/svg/employment.svg';
import {
  ReactComponent as calendarPicker
} from '../../../assets/svg/calendarPicker.svg';
import {
  ReactComponent as noDataInvite
} from '../../../assets/svg/no-data-invite.svg';
import {ReactComponent as checkGreen} from '../../../assets/svg/checkGreen.svg';
import {ReactComponent as checkWhite} from '../../../assets/svg/checkWhite.svg';
import {ReactComponent as warning} from '../../../assets/svg/warning.svg';
import {ReactComponent as envelope} from '../../../assets/svg/envelope.svg';

const iconSet = {
  logo,
  saudiLogo,
  instagram,
  twitter,
  linkedin,
  facebook,
  eye,
  qiwaLogo,
  applied,
  saved,
  done,
  shortlisted,
  interview,
  offer,
  login,
  star,
  users,
  reload,
  block,
  whyWork1,
  whyWork2,
  whyWork3,
  whyWork4,
  getStarted1,
  getStarted2,
  getStarted3,
  getStarted4,
  wifi,
  wifiOff,
  business,
  pdf,
  info,
  time,
  location,
  calendar,
  settings,
  logout,
  logout2,
  chart,
  shareArrow,
  dots,
  marker,
  posted,
  employment,
  calendarPicker,
  noDataInvite,
  checkGreen,
  checkWhite,
  warning,
  envelope
};

export type IconType = keyof typeof iconSet;

export type IconProps = {
  name: IconType
  onClick?: () => void
  className?: string
  id?: string
  style?: React.CSSProperties
  testId?: string
};

export const Icon = ({name, testId, ...rest}: IconProps) => {
  const IconSvg = iconSet[name]
  return IconSvg ? <IconSvg {...rest} data-testid={testId}/> : null
};
