import {useEffect, useState} from 'react';
import {NotificationsBox} from './styles'
import useStores from '../../../../../hooks/useStores'
import Title from './Title'
import NotificationList from './NotificationList'

const defaultDelay = 30 * 1000

export const Notifications = () => {
  const [isMounted, setIsMounted] = useState(false)
  const {userStore} = useStores()
  const {
    isNotificationOpen,
    fetchNotifications,
    notifications,
    setNotifications
  } = userStore

  useEffect(() => {
    const isBanned = notifications.length && notifications[0]?.type === 'USER_BANNED'
    const delay = isMounted && isBanned ? defaultDelay * 10 : defaultDelay
    const timer = setInterval(() => fetchNotifications()
      , delay)
    fetchNotifications()
    return () => {
      clearInterval(timer)
      setNotifications([])
    }
  }, [isMounted])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsMounted(true)
    }, defaultDelay)

    return () => {
      clearInterval(timeout)
    }
  }, []);

  return (
    <NotificationsBox isOpen={isNotificationOpen}>
      <Title/>
      <NotificationList/>
    </NotificationsBox>
  );
};
