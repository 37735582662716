import {useEffect} from 'react'
import {Role} from "../utils/roles";
import {TUser} from "../stores/userStore";
import useStores from "./useStores";

type Token = {
  email: string
  exp: number
  iat: number
  role: string
  sub: string
  blocked?: boolean
  employerName?: string
  'employer-type'?: 'LABOR_ATTACHE' | 'LABOR_ATTACHE_OFFICE' | 'GOVERNMENT'
}
export const useSetUser = (encryptedToken: Token) => {
  const {
    userStore: {fetchAvatar, setUser, fetchUser},
  } = useStores();
  useEffect(() => {
    if (encryptedToken.role === Role.jobSeeker) {
      setUser({
        userId: Number(encryptedToken.sub),
      } as TUser)
      fetchAvatar();
      fetchUser();
    }
  }, [encryptedToken.role, encryptedToken.sub]);
}