import React from 'react';
import {NotificationTitleWrapper, ReadAllBtn} from './styles'
import {useTranslation} from 'react-i18next'
import {Heading} from '../../../../components'
import useStores from '../../../../../hooks/useStores'
import {observer} from 'mobx-react'

const Title = () => {
  const {userStore: {readAllNotifications, notifications}} = useStores()
  const {t} = useTranslation()
  return (
    <NotificationTitleWrapper>
      <Heading variant='h2'>{t('common.notificationTitle')}</Heading>
      {!!notifications.length && <ReadAllBtn data-testid='read-all-btn' onClick={readAllNotifications}>
        {t('common.readAllBtn')}
      </ReadAllBtn>}
    </NotificationTitleWrapper>
  );
};

export default observer(Title);
