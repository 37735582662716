import React from 'react';
import {Component} from './styles';

type LinkProps = {
  children: React.ReactNode;
  href?: string;
  target?: string;
};

export const SocialLink = ({children, ...rest}: LinkProps) => {
  return <Component {...rest}>{children}</Component>;
};
