import styled, {css} from 'styled-components';
import themeMain from '../../../theme/themeMain';

type StyledComponentProps = {
  variant: 'primary' | 'secondary' | 'outline-green' | 'outline-red' | 'gold' | 'red' | "text-green";
  size?: 'big' | 'medium' | 'small';
};

export const Component = styled.button<StyledComponentProps>`
  ${(props) => props.theme.buttons[props.variant]};

  &:disabled {
    position: relative;
    cursor: not-allowed;
    background: #e0e0e0;
    color: ${themeMain.colors.white};
    border: none;

    &:hover,
    &:focus {
      background: #e0e0e0;

      .tooltipText {
        visibility: visible;
      }
    }
  }

  .tooltipText {
    visibility: hidden;
    width: 200px;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    border-radius: 6px;
    font-size: 13px;
    text-transform: lowercase;
    padding: 5px;
    position: absolute;
    z-index: 1;

    &:first-letter {
      text-transform: uppercase;
    }

    &:after {
      content: " ";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }

  ${({size}) =>
          size === 'big' &&
          css`
            min-height: 60px;
            width: 100%;
            padding: 12px 27px 11px;
            font-size: 16px;
          `}

  ${({size}) =>
          size === 'medium' &&
          css`
            min-height: 42px;
            padding: 13px 38px;
            font-size: 14px;
            font-weight: 600;
          `}
  
  ${({size}) =>
          size === 'small' &&
          css`
            min-height: 32px;
            padding: 5px 22px;
            font-size: 16px;
            font-weight: 600;
            text-transform: none;
          `}
`;
