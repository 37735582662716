import {getEnv} from "./getEnv";

const getBaseEndoint = getEnv('REACT_APP_BASE_ENDPOINT') || ''

export const demoORstageEnv = getBaseEndoint.includes('demo') || getBaseEndoint.includes('stage')

const src = `https://maps.googleapis.com/maps/api/js?key=${getEnv('REACT_APP_PLACES_API_KEY')}&libraries=places&language=en`;
const srcUat = `${getEnv('REACT_APP_BASE_ENDPOINT')}/map/api/js?libraries=places&language=en`;

export const initPlacesApi = () =>
  new Promise((resolve, reject) => {
      if ('google' in window) return resolve(true);
      const scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.id = 'location-autocomplete-library';
      scriptTag.src = demoORstageEnv ? src : srcUat;
      document.body.appendChild(scriptTag);
      scriptTag.addEventListener('load', resolve);
      scriptTag.addEventListener('error', reject);
  });
