import {makeAutoObservable} from 'mobx'

type Variants = 'success' | 'error' | 'warning' | 'maintenance'

type Timer =  number

interface NotificationStore {
  variant: Variants
  text: string
  textVars: object
  setText: (text: string) => void
  setVariant: (variant: Variants) => void
  setNotification: (text: string, variant: Variants) => void
  timer?: Timer
  additionalText: string;
  node?: React.ReactNode;
}

class Notification implements NotificationStore {
  variant: Variants = 'success'
  text: string = ''
  textVars = {}
  timer: Timer = 5000
  additionalText: string = ''
  node?: React.ReactNode | undefined


  constructor() {
    makeAutoObservable(this)
  }

  setVariant = (variant: Variants) => {
    this.variant = variant
  }

  setText = (text: string, textVars?: object) => {
    this.text = text
    this.textVars = textVars || {}
  }

  setNotification = (text: string, variant: Variants, timer: Timer = 5000, additionalText: string = '', textVars?: object, node?: React.ReactNode) => {
    this.text = text
    this.variant = variant
    this.timer = timer
    this.additionalText = additionalText
    this.textVars = textVars || {}
    this.node = node
  }
}

const notificationStore = new Notification()
export default notificationStore
