import React from 'react'
import {ErrorTextP} from './styles'

type ErrorTextProps = {
  text: string
  style?: React.CSSProperties
  textAlign?: 'center' | 'left' | 'right' | 'start' | 'end'
}

export const ErrorText = (props: ErrorTextProps) => {
  const {text, style, textAlign} = props

  return (
    <ErrorTextP data-testid='error-text' className='error-text' style={{textAlign, ...style}}>
      {text}
    </ErrorTextP>
  );
};