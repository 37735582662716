import userStore from './userStore';
import hrPoolStore from './hrPoolStore';
import jobOpeningStore from "./jobOpeningStore";
import notificationStore from './notificationStore';
import jobApplicationStore from "./jobApplicationStore";
import jsCalendarStore from "./calendarStore";
import interviewStore from "./interviewStore";
import jsHistoryStore from "./jsHistoryStore";
import scheduleInterviewStore from "./scheduleInterviewStore";
import committeeCalendarStore from "./committeeCalendarStore";
import laoCalendarStore from "./laoCalendarStore";

// :TODO
// REFACTOR STORES, USE REACT-QUERY IF IT IS NEEDED
const stores = {
  userStore,
  hrPoolStore,
  jobOpeningStore,
  jobApplicationStore,
  notificationStore,
  jsCalendarStore,
  committeeCalendarStore,
  laoCalendarStore,
  interviewStore,
  jsHistoryStore,
  scheduleInterviewStore
};

export default stores;