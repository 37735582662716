// General
export const ACCOUNT = '/account/*';
export const EMPLOYERS = '/employers/*';
export const ADMIN = '/admin/*'
export const COMMITTEE = '/committee/*'
export const LOGIN = '/login'

// Employers
export const EMPLOYER_JOBSEEKER_RESUME = 'jobseeker'
export const EMPLOYER_JO_LIST = 'job-openings'
export const EMPLOYER_JOBSEEKERS = 'jobseekers'
export const EMPLOYER_JO_CREATE = 'job-openings/create'
export const EMPLOYER_HIRING_CREATE = 'hiring'
export const EMPLOYER_HIRING_REQUESTS = 'hiring-requests'
export const EMPLOYER_LIST = 'list'
export const EMPLOYER_BLACKLIST = 'blacklist'
export const EMPLOYER_PASS_POOL = 'pass-pool'
export const EMPLOYER_DASHBOARD = 'dashboard'
export const EMPLOYER_INTERVIEW = 'interview'
export const EMPLOYER_RECEPTIONISTS = 'receptionists'
export const EMPLOYER_TIME_SLOTS = 'time-slots'
export const EMPLOYER_ATTENDEES = 'attendees'
export const EMPLOYER_PASSPORT_TRAVEL = 'passport-travel'

// Admin
export const ADMIN_DASHBOARD = 'adminDashboard'
export const ADMIN_GOVERNMENT_EMPLOYERS = 'government-employers'
export const ADMIN_JOBSEEKERS = 'jobseekers'
export const ADMIN_JOB_OPENINGS = 'openings'
export const ADMIN_LIST = 'list'
export const ADMIN_BLACKLIST = 'blacklist'
export const ADMIN_REQUESTS = 'requests'
export const COMMITTEE_LIST = 'committees'

//Job Seeker
export const RESUME = 'resume'
export const USER_DASHBOARD = 'dashboard'
export const OPENINGS = 'openings'
export const JOB_APPLICATIONS = 'job-applications'
export const CALENDAR = 'my-calendar'
export const INTERVIEW = 'interview'
export const OFFER = 'offer'
export const GO_TO_USER_HISTORY = 'history'
export const SETTINGS = 'settings'

// committee
export const COMMITTEE_JO_LIST = 'job-openings'
export const COMMITTEE_EVALUATION_LIST = 'evaluation-list'
export const COMMITTEE_JS_RESUME = 'job-seeker/resume'
export const HEAD_OF_COMMITTEE_EVALUATION_LIST = '/head-list'
export const HEAD_OF_COMMITTEE_LIST = '/job/evaluation/v1/head/list'
export const EVALUATION_BY_HEAD = '/job/evaluation/v1/head/review'
export const COMMITTEE_CALENDAR = 'calendar'

// Receptionist
export const RECEPTIONIST = 'receptionist/*'
export const RECEPTIONIST_INTERVIEW = 'interview'

// Administrative committee
export const ADMINISTRATIVE_COMMITTEE = 'admin-committee/*'
export const GO_TO_ADMIN_COMMITTEE = '/admin-committee'


// Redirect Routes
export const GO_TO_ACCOUNT = '/account'
export const GO_TO_EMPLOYERS = '/employers'
export const GO_TO_COMMITTEE = '/committee'
export const GO_TO_RECEPTIONIST = '/receptionist'

export const GO_TO_EMPLOYER_APPLICANTS = (id: string) => `/employers/job-openings/${id}/applicants`
export const GO_TO_JOB_OPENING = '/employers/job-openings'
export const GO_TO_JOBSEEKER_RESUME_BY_COMMITTEE = '/committee/job-seeker/resume'
export const GO_TO_HIRING_REQUESTS = '/employers/hiring-requests'
export const GO_TO_EMPLOYER_JOBSEEKER_RESUME = '/employers/jobseeker'
export const GO_TO_ADMIN_DASHBOARD = '/admin/adminDashboard'
export const GO_TO_ADMIN_JOBSEEKERS = '/admin/jobseekers'
export const GO_TO_ADMIN_JOB_OPENINGS = '/admin/openings'
export const GO_TO_ADMIN_JOBSEEKERS_RESUME = '/admin/jobseeker'
export const GO_TO_ADMIN_GOVERNMENT_EMPLOYERS = '/admin/government-employers'
export const GO_TO_ADMIN_BLACKLIST = '/admin/list'
export const GO_TO_ADMIN_COMMITTEES = '/admin/committees'
export const GO_TO_JOB_OPENINGS = '/account/openings'
export const GO_TO_OFFER = '/account/offer'
export const GO_TO_ADMIN_JO_DETAILS = (id: number | string) => `/admin/openings/${id}`
export const GO_TO_ADMIN_JS_DETAILS = (id: number | string) => `/admin/jobseeker/${id}`
export const GO_TO_EMPLOYER_HIRING_REQUESTS = (id: string) => `/employers/${EMPLOYER_HIRING_REQUESTS}/${id}`
export const GO_TO_EMPLOYER_JOBOPENING_DETAILS = (id: string | number) => `/employers/${EMPLOYER_JO_LIST}/${id}`
export const GO_TO_HIRING = '/employers/hiring'
export const GO_TO_EMPLOYER_CREATE_JO_WITH_ID = (id: string | number) => `/employers/${EMPLOYER_JO_CREATE}/${id}`
export const GO_TO_COMMITTEE_EVALUATION_LIST = (id: string | number) => `/committee/${COMMITTEE_EVALUATION_LIST}/${id}`
export const GO_TO_HEAD_OF_COMMITTEE_EVALUATION_LIST = (id: string | number) => `/committee/head-list/${id}`
export const GO_TO_COMMITTEE_JO_DETAILS = (id: string | number) => `/committee/${COMMITTEE_JO_LIST}/${id}`
export const GO_TO_COMMITTEE_JS_RESUME = (id: string | number) => `/committee/${COMMITTEE_JS_RESUME}/${id}`
export const GO_TO_COMMITTEE_EVALUATE_FORM = (id: string | number, evaluationId: string | number) => `/committee/${COMMITTEE_EVALUATION_LIST}/${id}/evaluate/${evaluationId}`
export const GO_TO_COMMITTEE_REEVALUATE_FORM = (id: string | number, evaluationId: string | number) => `/committee/${COMMITTEE_EVALUATION_LIST}/${id}/reevaluate/${evaluationId}`
export const GO_TO_CREATE_OFFER = (id: string | number, jobSeekerId: string | number) => `${GO_TO_JOB_OPENING}/${id}/create-offer?jobSeekerId=${jobSeekerId}`
export const GO_TO_COMMITTEE_EVALUATION_DETAILS = (id: string | number, evaluationId: string | number) => `/committee/${COMMITTEE_EVALUATION_LIST}/${id}/evaluation/${evaluationId}/details`
