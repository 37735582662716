import React from 'react';
import {
  SkillBoxComponent,
  SkillContentComponent,
  SkillHeaderComponent,
  SkillTooltipComponent,
  SkillElementComponent,
  SkillBodyComponent,
  SkillRowComponent,
  SkillColComponent,
  SkillButtonsComponent,
  SkillFieldDynamicComponent,
} from './styles';

type ContainerProps = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

interface SkillProps extends ContainerProps {
  isBanned?: boolean;
  isCompleted?: boolean;
  className? : string;
  emptyFetch? : boolean;
  statusError? : boolean;
}

export const SkillBox = ({children, isBanned, isCompleted, className, emptyFetch, statusError, ...rest}: SkillProps) => {
  return <SkillBoxComponent statusError={statusError} emptyFetch={emptyFetch} className={className} isBanned={isBanned} isCompleted={isCompleted} {...rest}>{children}</SkillBoxComponent>;
};

export const SkillHeader = ({children, ...rest}: ContainerProps) => {
  return <SkillHeaderComponent {...rest}>{children}</SkillHeaderComponent>;
};

export const SkillContent = ({children, ...rest}: ContainerProps) => {
  return <SkillContentComponent {...rest}>{children}</SkillContentComponent>;
};

export const SkillTooltip = ({children, ...rest}: ContainerProps) => {
  return <SkillTooltipComponent {...rest}>{children}</SkillTooltipComponent>;
};

export const SkillElement = ({children, ...rest}: ContainerProps) => {
  return <SkillElementComponent {...rest}>{children}</SkillElementComponent>;
};

export const SkillBody = ({children, ...rest}: ContainerProps) => {
  return <SkillBodyComponent {...rest}>{children}</SkillBodyComponent>;
};

export const SkillRow = ({children, ...rest}: ContainerProps) => {
  return <SkillRowComponent {...rest}>{children}</SkillRowComponent>;
};

export const SkillCol = ({children, ...rest}: ContainerProps) => {
  return <SkillColComponent {...rest}>{children}</SkillColComponent>;
};

export const SkillButtons = ({children, ...rest}: ContainerProps) => {
  return <SkillButtonsComponent {...rest}>{children}</SkillButtonsComponent>;
};

export const SkillFieldDynamic = ({children, ...rest}: ContainerProps) => {
  return (
    <SkillFieldDynamicComponent {...rest}>
      {children}
    </SkillFieldDynamicComponent>
  );
};
