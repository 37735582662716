import {
  FetchCardsResponse,
  Filters
} from "../../../../../../stores/jobOpeningStore";
import apiWrapper from "../../../../../../api/axiosInterceptor";
import {GET_JOB_OPENINGS} from "../../../../../../constants/api";
import {JOAppliedPayload} from "../types";
import {InterviewDetailsProps} from "../../../api/job/types";

export const getJobOpeningsList = async (mappedFilters: Filters) => {
  return await apiWrapper.post<FetchCardsResponse>(GET_JOB_OPENINGS, mappedFilters)
}

export const getJOStatusUserPerspective = async (jobId: string) => {
  try {
    const response = await apiWrapper.get(`/job/${jobId}/application/v1`);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const applyJO = async (data: JOAppliedPayload) => {

  const method = data.method;

  try {
    const response = method === 'post' ? await apiWrapper.post(`/job/${data.jobId}/application/v1`, data) :
      await apiWrapper.put(`/job/${data.jobId}/application/v1`, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const saveJO = async (data: JOAppliedPayload) => {
  try {
    const response = await apiWrapper.post(`/job/${data.jobId}/application/v1`, data);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const removeJO = async (jobId: string) => {
  try {
    const response = await apiWrapper.delete(`/job/v1/${jobId}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const closeJO = async (jobId: string | number) => {
  try {
    const response = await apiWrapper.put(`/job/${jobId}/close`);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const republishJO = async (jobId: string | number) => {
  try {
    const response = await apiWrapper.post(`/job/${jobId}/republish`);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const getJobOpeningInterviewDetails = async ({jobOpeningId, jobSeekerId}: InterviewDetailsProps) => {
  try {
    const response = await apiWrapper.get(`/interview/job/${jobOpeningId}/job-seeker/${jobSeekerId}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};
