import styled from 'styled-components'
import themeMain from '../../../../../theme/themeMain'
import rtl from "styled-components-rtl";

type NotificationsProps = {
  isOpen: boolean
}

export const NotificationsBox = styled.div<NotificationsProps>`
  position: absolute;
  top: calc(100% + 2px);
  ${rtl`right: 60px;`};
  max-width: 440px;
  width: 100%;
  background: ${themeMain.colors.white};
  border-radius: 4px;
  opacity: ${({isOpen}) => isOpen ? 1 : 0};
  visibility: ${({isOpen}) => isOpen ? 'visible' : 'hidden'};
  transition: .3s all;
  box-shadow: ${themeMain.boxShadow.block};
  h2 {
    margin: 0;
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    max-width: 100%;
    top: 88px;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const NotificationTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid ${themeMain.colors.gray.light};
`;

export const ReadAllBtn = styled.button`
  background: transparent;
  outline: none;
  appearance: none;
  border: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: ${themeMain.colors.green};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const NotificationListWrapper = styled.div`
  overflow-y: auto;
  max-height: 480px;
`;

export const NotificationItem = styled.div`
  position: relative;
  padding: 12px 32px;
  cursor: pointer;

  &.new {
    background: ${themeMain.colors.paleBlue};
  }

  & p {
    margin: 0;
  }

  & .createdAt {
    font-size: 12px;
    color: ${themeMain.colors.gray.medium};
  }

  & .title-new {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      ${rtl`left: -16px;`};
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${themeMain.colors.red};
    }
  }

  & .remove-icon {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    top: 11px;
    ${rtl`right: 23px;`};
    border-radius: 50%;
    padding: 5px;
    z-index: 1;
    transition: .3s all;

    &:hover {
      background: ${themeMain.colors.gray.light};
    }
  }

  &:hover {
    & .remove-icon {
      opacity: 1;
      pointer-events: auto;
    }
  }
  
  a{
    margin-inline-start: 5px;
    color:  ${themeMain.colors.green};
    text-decoration: none;
  }
`;

export const NoDataWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  padding: 55px 55px 65px;
  & div {
    h3 {
      margin: 0;
      color: ${themeMain.colors.gray.dark};
    }
    p {
      color: ${themeMain.colors.gray.dark};
    }
  }
`;
