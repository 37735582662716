import {ReactComponent as NoDataSvg} from "../../../assets/svg/no-data.svg";
import {NoDataWrapper} from "./styles";
import {Heading, Text} from "../Typography";
import {useTranslation} from "react-i18next";

type Props = {
  title?: string
  text?: string
}

export const NoData = (props: Props) => {
  const {title = 'common.noData', text = ''} = props
  const {t} = useTranslation()
  return (
    <NoDataWrapper>
      <NoDataSvg/>
      <Heading variant='h4'>{t(title)}</Heading>
      {text && <Text>{t(text)}</Text>}
    </NoDataWrapper>
  );
};