import App from './App';
import reportWebVitals from './reportWebVitals';
import {createRoot} from 'react-dom/client'
import stores from './stores'
import i18nInstance from './localization/i18n'
import {QueryClient, QueryClientProvider} from 'react-query'
import {ReactQueryDevtools} from 'react-query/devtools'
import {Provider} from 'mobx-react'
import {I18nextProvider} from 'react-i18next'
import {BrowserRouter} from 'react-router-dom'
import {ServerError} from './api/types'
import initSentry from "./utils/sentry";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      retry: (failureCount, error) => {
        const err = error as ServerError
        if (err.status && err.status >= 500) return true
      },
      retryDelay: 1000,
      refetchOnWindowFocus: false
    }
  },
});

initSentry()

root.render(
  <Provider {...stores}>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18nInstance}>
        <BrowserRouter>
          <App/>
        </BrowserRouter>
      </I18nextProvider>
      <ReactQueryDevtools initialIsOpen={false}/>
    </QueryClientProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
