import React from 'react';
import {Text} from '../Typography'
import {TooltipWrapper} from './styles'

export type TooltipProps = {
  text: string
  className?: string
  style?: React.CSSProperties
}

export const Tooltip = (props: TooltipProps) => {
  const {text, className, style} = props
  return (
    <TooltipWrapper className={className} style={style}>
      <Text variant='regular'>
        <i className='mi mi-ic_info_48px'/>
        {text}
      </Text>
    </TooltipWrapper>
  );
};