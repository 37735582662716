import {getEnv} from "./getEnv";

export const initZendeskApi = () =>
	new Promise((resolve, reject) => {
		const scriptTag = document.createElement('script');
		scriptTag.type = 'text/javascript';
		scriptTag.id = 'ze-snippet';
		scriptTag.src = `https://static.zdassets.com/ekr/snippet.js?key=${getEnv('REACT_ZENDESK_KEY')}`;
		document.body.appendChild(scriptTag);
		scriptTag.addEventListener('load', resolve);
		scriptTag.addEventListener('error', reject);
	});