import {
  LoginPayload,
  LoginPayloadOtp,
  UpdateEmployerPasswordPayload
} from "./types";
import api from "../../../../api/axiosInterceptor";
import {UPDATE_EMPLOYER_PASSWORD} from "../../../../constants/api";

export const login = async (data: LoginPayload) => {
  try {
    const response = await api.post('/auth/login', data);
    return response.data;
  } catch (error){
    return Promise.reject(error.response);
  }
};

export const updateEmployerPassword = async (data: UpdateEmployerPasswordPayload) => {
  try {
    return await api.post(UPDATE_EMPLOYER_PASSWORD, data);
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const loginOtp = async (data: LoginPayloadOtp) => {
  try {
    const response = await api.post('/auth/otp/login', data);
    return response.data;
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const logout = async () => {
  const getRefreshToken = localStorage.getItem('refreshToken') ?? '';
  const accessToken = localStorage.getItem('accessToken') ?? "";
  try {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    const response = await api.post('/auth/logout', {
      refreshToken: getRefreshToken,

    },{
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });
    return response.data;
  } catch (err) {
    return Promise.reject(err.response);
  }
};