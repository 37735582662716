import axios from 'axios';
import jwtDecode from 'jwt-decode';
import {IObjectKeys} from "./types";
import {getEnv} from "../utils/getEnv";
import {LOGIN} from '../constants/routes'
import {SENTRY_KEY} from "../utils/sentry";
import * as Sentry from "@sentry/react";

type EnctyptedType = {
  exp: number | string;
};

const api = axios.create({
  baseURL: getEnv('REACT_APP_API_ENDPOINT'),
});

api.interceptors.request.use(async (config) => {
  const urlArr = [
    '/auth/signup',
    '/auth/login',
    '/auth/refresh-token',
    '/auth/logout',
  ];

  const url: string = config.url ?? '';

  const AuthToken = localStorage.getItem('accessToken') ?? '';
  let encryptedToken: EnctyptedType;

  let tokenExpDate;

  if (!urlArr.includes(url) && AuthToken) {
    encryptedToken = jwtDecode(AuthToken);
    tokenExpDate = +encryptedToken.exp * 1000;
  }

  const curDate = Date.now();

  if (Number(tokenExpDate) - curDate < 1000 && !urlArr.includes(url)) {
    await refreshToken();
  }

  const LangVocabulary: IObjectKeys = {
    'en': 'en_US',
    'ar': 'ar_SA'
  }

  const getAuthToken = localStorage.getItem('accessToken') ?? '';
  const currentLanguage = localStorage.getItem('language') ?? 'en';

  return {
    ...config,
    headers: {
      ...config.headers,
      'Accept-Language': LangVocabulary[currentLanguage],
      ...(!urlArr.includes(url) && {Authorization: `Bearer ${getAuthToken}`}),
    },
  };
});

const checkIsUrlTokenRefreshForbidden = (url: string) => {
  const urlArr = ['/auth/signup', '/auth/login', '/auth/logout', '/auth/refresh-token'];

  return urlArr.find((str) => {
    return url.includes(str);
  });
};

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken');

  try {
    const response = await api.put('/auth/refresh-token', {
      refreshToken: refreshToken,
    });
    localStorage.setItem('accessToken', response.data.accessToken);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    return response;
  } catch (err) {
    console.log('interceptor error');
    const otpPopupIsVisible = await localStorage.getItem('otpPopupIsVisible');
    localStorage.removeItem('otpPopupIsVisible');
    if (!otpPopupIsVisible) {
      window.navigate(LOGIN)
    }
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');

    return Promise.reject(err);
  }
};

const createAxiosResponseInterceptor = () => {
  const interceptor = api.interceptors.response.use(
    (response) => {
      const reqPayload = response?.config?.data && typeof response?.config?.data === 'string'
        ? JSON.parse(response.config.data)
        : {}
      if (reqPayload.size && response.data.total) {
        response.data.totalPages = response.data.total < reqPayload.size ? 0 : Math.ceil(response.data.total / reqPayload.size)
      }
      return response
    },
    async (error) => {
      if ((error?.response?.status === 404 || error?.response?.status === 401) && error.response.config.url === '/auth/refresh-token') {
        window.navigate(LOGIN)
      }
      if (SENTRY_KEY) {
        Sentry.withScope(function (scope) {
          scope.setFingerprint([error.config.method, error.config.baseURL + error.config.url, String(error.response.status)])
          Sentry.captureException(error?.data?.messages?.[0]?.key?.value || error)
        })
      }
      if (
        !error ||
        error?.response?.status !== 401 ||
        checkIsUrlTokenRefreshForbidden(error.response.config.url)
      ) {
        return Promise.reject(error);
      }

      api.interceptors.response.eject(interceptor);

      try {
        await refreshToken();
        return api(error.response.config);
      } catch (err) {
        return Promise.reject(error);
      } finally {
        createAxiosResponseInterceptor();
      }
    }
  );
};

createAxiosResponseInterceptor();

export default api;
