import React, {InputHTMLAttributes} from 'react';
import {Wrapper} from './styles'

interface RadioButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  id: string
  label: string | React.ReactNode;
  testId?: string
}

export const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  (
    {
      testId,
      type = 'radio',
      label,
      id,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <Wrapper>
        <input id={id} {...props} type={type} data-testid={testId} ref={ref}/>
        <label htmlFor={id} className={className}>
          {label}
        </label>
      </Wrapper>
    );
  }
);