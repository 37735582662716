import {SyntheticEvent, useState} from "react";
import {TabMenuProps, TabProps, TabViewProps} from "./types";
import {TabMenu, TabWrapper, TabContent} from "./styles";
import {useSearchParams} from "react-router-dom";

export const TabControl = ({...props}) => {
  return <div {...props} />;
}

export const TabView = ({...props}) => {
  return <div style={props.style}>{props.children}</div>;
}

export const Tabs = (props: TabProps) => {
  const [bindIndex, setBindIndex] = useState(props.defaultIndex);
  const [, setSearchParams] = useSearchParams();

  const changeTab = ({e, index}: { e: SyntheticEvent, index: string }) => {
    e.preventDefault()
    if (typeof props.onTabClick === "function") {
      props.onTabClick(index);

    }
    if (props.onSwitchClear) {
      setSearchParams({})
    }
    setBindIndex(index);
  };

  const items = props?.children.filter((item) => item?.props.className === "TabControl");

  const tabViewItems = props?.children.filter((item) => item?.props.className === "TabView");

  return (
    <TabWrapper>
      <TabMenu>
        {items.map(({props}: { props: TabMenuProps }) => (
          <button
            onClick={(e) => changeTab({e, index: props.index})}
            className={bindIndex === props.index ? "tabs-button active" : "tabs-button"}
            key={props.index}
            data-testid={`tab-button-${props.index}`}
          >
            <span>{props.label}</span>
          </button>
        ))}
      </TabMenu>
      <TabContent style={props.tabContentStyles}>
        {tabViewItems.map(({props}: { props: TabViewProps }) => (
          <div
            className="tab-view_item"
            key={props.index}
            data-testid={`tab-view-${props.index}`}
            style={{display: bindIndex === props.index ? "flex" : "none", ...props.style}}
          >
            {props.children}
          </div>
        ))}
      </TabContent>
    </TabWrapper>
  );
}
