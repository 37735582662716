import {
  ContainerComponent,
  HeaderLinkGroupComponent,
  HeaderContainerComponent,
} from './styles';

type ContainerProps = {
  children?: React.ReactNode;
  big?: boolean
};

export const Container = ({big,children, ...rest}: ContainerProps) => {
  return <ContainerComponent big={big} {...rest}>{children}</ContainerComponent>;
};

export const HeaderContainer = ({children, ...rest}: ContainerProps) => {
  return (
    <HeaderContainerComponent {...rest}>{children}</HeaderContainerComponent>
  );
};

export const HeaderLinkGroup = ({children, ...rest}: ContainerProps) => {
  return (
    <HeaderLinkGroupComponent {...rest}>{children}</HeaderLinkGroupComponent>
  );
};
