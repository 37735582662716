import * as Sentry from "@sentry/react";
import {getEnv} from "./getEnv";

export const SENTRY_KEY = getEnv('REACT_APP_SENTRY_KEY')

function initSentry() {
  const SENTRY_REPLAY = getEnv('REACT_APP_SENTRY_REPLAY')
  if (SENTRY_KEY) {
    Sentry.init({
      dsn: SENTRY_KEY,
      integrations: [
        new Sentry.BrowserTracing(),
        ...(SENTRY_REPLAY === 'true' ? [new Sentry.Replay()] : [])
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
}

export default initSentry
