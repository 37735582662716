import {Navigate, useLocation} from 'react-router-dom';
import {Footer} from '../Footer';
import {Header} from '../Header';
import {SuccessMsg} from '../../components';
import React, {useMemo} from 'react';
import {useAuthInfo} from '../../../hooks/useAuthInfo'

type PrivateRouteProps = {
  children: React.ReactNode
  roles: string
};

export const PrivateRoute = ({
                               children,
                               roles
                             }: PrivateRouteProps): React.ReactNode => {
  const location = useLocation()

  const {getAuthToken, ...encryptedToken} = useAuthInfo()

  const header = useMemo(()=> <Header isPrivate={!!getAuthToken}
                                      isLanding={false}/>, [!!getAuthToken])

  if (!encryptedToken.role) return <Navigate to='/login'
                                             state={{referrer: location.pathname}}/>

  return roles && roles === encryptedToken.role ? (
    <>
      {header}
      <main id='main'>
        <SuccessMsg/>
        {children}
      </main>
      <Footer/>
    </>
  ) : (
    <Navigate to='/login'/>
  )
}
