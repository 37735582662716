import {makeAutoObservable} from 'mobx'
import api from '../api/axiosInterceptor'
import {GET_JOB_APPLICATIONS} from '../constants/api'
import getErrorText from '../utils/getErrorText'

export type Card = {
  logoFileName: string;
  companyName: string;
  employmentType: string;
  jobApplicationStatus: string;
  jobId: number;
  offerId: number;
  jobOpeningStatus: string;
  city: string;
  title: string;
}

export type JobApplicationStatusType = 'SAVED' | 'APPLIED' | 'SHORT_LISTED' | 'OFFER' | 'INTERVIEW' | 'DONE'

type Filters = {
  jobApplicationStatus?: JobApplicationStatusType[]
  page?: number
  size?: number
}

type FetchCardsResponse = {
  total: number
  pageNumber: number
  content: Card[]
}

interface JobApplicationStore {
  total: number
  loading: boolean
  error: string
  pageAmount: number
  filters: Filters
  cards: Card[]
  setLoading: (loading: boolean) => void
  setCards: (cards: Card[]) => void
  setTotal: (size: number, total: number) => void
  setError: (error: string) => void
  setFilters: (filters: Filters) => void
}

const initialFilters = {
  size: 12,
  jobApplicationStatus: []
} as Filters

class JobApplicationPool implements JobApplicationStore {
  total: number = 1
  pageAmount = 1
  error: string = ''
  loading: boolean = true
  filters: Filters = initialFilters
  cards: Card[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  setCards = (cards: Card[]) => {
    this.cards = cards
  }

  setError = (error: string) => {
    this.error = error
  }

  setFilters = (filters: Filters): void => {
    this.filters = {
      ...initialFilters,
      ...filters
    }
  }

  setTotal = (size: number, total: number) => {
    this.total = Math.ceil(total / size)
  }

  fetchCards = async () => {
    this.filters['page'] = this.filters['page'] ? +this.filters['page'] - 1 : 0;

    this.setLoading(true)
    this.setError('')
    try {
      const mappedFilters = Object.keys(this.filters).reduce((acc, curr) => {
        const key = curr as keyof Filters
        if (Array.isArray(this.filters[key])) {
          if ((this.filters[key] as []).length) {
            return {
              ...acc,
              [key]: this.filters[key]
            }
          }
        }
        if (!Array.isArray(this.filters[key]) && this.filters[key]) {
          return {
            ...acc,
            [key]: this.filters[key]
          }
        }
        return acc
      }, {} as Filters)
      const response = await api.post<FetchCardsResponse>(GET_JOB_APPLICATIONS, mappedFilters)
      this.setCards(response.data.content)
      this.setTotal(this.filters['size'] ?? 12, response.data.total)

    } catch (e) {
      this.setError(getErrorText(e?.data?.messages?.[0]?.key?.value))
    } finally {
      this.setLoading(false)
    }
  }
}

const jobApplicationStore = new JobApplicationPool()
export default jobApplicationStore
