import jwtDecode from 'jwt-decode';

type Token = {
  email: string
  exp: number
  iat: number
  role: string
  sub: string
  blocked?: boolean
  employerName?: string
  'employer-type'?:  'LABOR_ATTACHE' | 'LABOR_ATTACHE_OFFICE' | 'GOVERNMENT'
}

export const useAuthInfo = () => {
  const getAuthToken = localStorage.getItem('accessToken') ?? '';

  let encryptedToken: Token = {
    email: '',
    exp: 0,
    iat: 0,
    role: '',
    sub: '',
  };

  if (getAuthToken) {
    try {
      encryptedToken = jwtDecode(getAuthToken);
    } catch (error) {
      console.log('error', error);
    }
  }

  return {...encryptedToken, getAuthToken}
}
