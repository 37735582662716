import styled from "styled-components";

export const CountryFlagWrapper = styled.div`
  input {
    direction: ltr;
  }
  
  img {
    max-width: 100%;
  }
`;