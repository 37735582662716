import {useEffect} from 'react';
import {LinkButton, NavItem, Icon} from '../../components';
import {HeaderLinkGroup} from '../Container';
import {
  AvatarDropdownButton,
  AvatarDropdownIcon,
  StyledBurger,
  Ul
} from './styles';
import AvatarDropdown from '../../modules/AvatarDropdown';
import {useLocation, useNavigate} from 'react-router-dom';
import useStores from '../../../hooks/useStores';
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {useAuthInfo} from '../../../hooks/useAuthInfo'
import {
  COMMITTEE_CALENDAR,
  COMMITTEE_JO_LIST, EMPLOYER_DASHBOARD,
  EMPLOYER_HIRING_REQUESTS, EMPLOYER_INTERVIEW,
  EMPLOYER_JO_LIST,
  EMPLOYER_LIST,
  GO_TO_ADMIN_BLACKLIST,
  GO_TO_ADMIN_COMMITTEES,
  GO_TO_ADMIN_DASHBOARD,
  GO_TO_ADMIN_GOVERNMENT_EMPLOYERS,
  GO_TO_ADMIN_JOB_OPENINGS,
  GO_TO_ADMIN_JOBSEEKERS,
  EMPLOYER_JOBSEEKERS,
  LOGIN, EMPLOYER_PASSPORT_TRAVEL,
  SETTINGS
} from '../../../constants/routes'
import {LangSwitch} from "./Switch";
import {Role} from "../../../utils/roles";
import {getEnv} from '../../../utils/getEnv'
import Notification
  from '../../private/jobseeker/components/Notifications/notificationBell'
import {useScreenSize} from '../../../hooks/useScreenSize'
import {useLogout} from "../../auth/Login/api/mutations";

type BurgerProps = {
  isPrivate?: boolean;
  setOpen: (open: boolean) => void;
  open: boolean;
};

type RolesLinks = {
  path: string
  name: string
  forBanned?: boolean
}

type IObjectKeys = {
  [key: string]: RolesLinks[]
};

const buttonStyles = {
  backgroundColor: 'transparent',
}

export const Burger = observer(({isPrivate, setOpen, open}: BurgerProps) => {
  const nav = useNavigate()
  const {t} = useTranslation()
  const isNetbook = useScreenSize(1024)
  const {pathname} = useLocation();
  const isLanding = pathname === '/'

  const {
    userStore: {resetUser},
    notificationStore: {setNotification}
  } = useStores();

  const {...encryptedToken} = useAuthInfo()
  const isAuth = !!encryptedToken.role

  const isRtl = (document.body.dir === 'rtl')

  const links: IObjectKeys = {
    ROLE_JOB_SEEKER: [
      ...getEnv('REACT_APP_SHOW_DASHBOARD') === 'true' ? [{
        path: '/account/dashboard',
        name: 'nav.dashboard'
      }] : [],
      {path: '/account/resume', name: 'nav.resume', forBanned: true},
      ...getEnv('REACT_APP_SHOW_JOB_OPENINGS') === 'true' ? [{
        path: '/account/openings',
        name: 'nav.openings'
      }] : [],
      {path: '/account/job-applications', name: 'nav.applications'},
      {path: '/account/my-calendar', name: 'nav.calendar'}
    ],
    ROLE_EMPLOYER: [
      ...encryptedToken['employer-type'] === 'LABOR_ATTACHE' ? [
        {path: EMPLOYER_DASHBOARD, name: 'nav.dashboard'},
        {path: EMPLOYER_JO_LIST, name: 'nav.openings'},
        {path: EMPLOYER_LIST, name: 'nav.laoList'}
      ] : [],
      ...encryptedToken['employer-type'] === 'LABOR_ATTACHE_OFFICE' ? [
        {
          path: EMPLOYER_JO_LIST,
          name: 'nav.openings'
        },
        {path: EMPLOYER_HIRING_REQUESTS, name: 'nav.hiringRequests'},
        {path: EMPLOYER_LIST, name: 'nav.laoList'},
        {path: EMPLOYER_INTERVIEW, name: 'nav.interview'},
        {path: EMPLOYER_PASSPORT_TRAVEL, name: 'nav.passportTravel'},
        {path: EMPLOYER_JOBSEEKERS, name: 'nav.jobseekers'}
      ] : [],

    ],
    ROLE_ADMIN: [
      {
        path: GO_TO_ADMIN_DASHBOARD,
        name: 'nav.adminDashboard'
      },
      ...getEnv('REACT_APP_SHOW_ADMIN_JS') === 'true'
        ? [{
          path: GO_TO_ADMIN_JOBSEEKERS,
          name: 'nav.jobseekers'
        }]
        : [],
      {
        path: GO_TO_ADMIN_JOB_OPENINGS,
        name: 'nav.openings'
      },
      {
        path: GO_TO_ADMIN_GOVERNMENT_EMPLOYERS,
        name: 'nav.employers'
      },
      {
        path: GO_TO_ADMIN_BLACKLIST,
        name: 'nav.blackList'
      },
      {
        path: GO_TO_ADMIN_COMMITTEES,
        name: 'nav.committees'
      }
    ],
    ROLE_COMMITTEE: [
      {
        path: COMMITTEE_JO_LIST,
        name: 'nav.openings'
      },
      {
        path: COMMITTEE_CALENDAR,
        name: 'nav.committeeCalendar'
      },
    ],
    ROLE_RECEPTIONIST: [
      {
        path: 'interview',
        name: 'nav.interview'
      }
    ],
    ROLE_ADMIN_COMMITTEE: [
      {
        path: 'job-openings',
        name: 'nav.openings'
      }
    ]
  }

  const navigate = useNavigate();
  const {mutateAsync} = useLogout();

  const handleLogout = async () => {
    try {
      setNotification('', 'success')
      resetUser();
      navigate(LOGIN);
      await mutateAsync();
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleResize = () => {
    setOpen(false)
  }

  const handleClick = () => {
    !isLanding ? nav(SETTINGS) : nav('/account/settings')
    setOpen(false)
  }

  useEffect(() => {
    document.body.style.overflow = open ? 'hidden' : 'auto';
    document.body.style.height = open ? '100%' : 'auto';
    document.body.style.width = open ? '100%' : 'auto';
    document.body.style.position = open ? 'fixed' : 'static';
  }, [open]);

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, []);

  return (
    <>
      <StyledBurger open={open} onClick={() => setOpen(!open)}>
        <div data-testid={'burger-icon'}/>
        <div/>
        <div/>
      </StyledBurger>
      {!isPrivate && !isAuth ? (
        <Ul open={open} isRtl={isRtl} isLanding={isLanding}>
          <HeaderLinkGroup>
            <LinkButton
              to='/login'
              variant={'secondary'}
              style={isLanding ? buttonStyles : undefined}
              onClick={() => setOpen(false)}
            >
              {t('header.login')}
            </LinkButton>
            <LinkButton
              to='/register'
              variant={isLanding ? 'primary' : 'secondary'}
              onClick={() => setOpen(false)}
            >
              {t('header.register')}
            </LinkButton>
            <span style={{marginLeft: '16px'}}>
              <LangSwitch isLanding={isLanding}/>
            </span>
          </HeaderLinkGroup>
        </Ul>
      ) : (
        <>
          <Ul open={open} isRtl={isRtl}>
            {!isLanding &&
              <nav>
              <ul>
                {encryptedToken?.role && links[encryptedToken?.role]?.map((item) => {
                  if (encryptedToken?.blocked) {
                    return item.forBanned && (
                      <li key={item.path}>
                        <NavItem to={item.path}
                                 onClick={() => setOpen(false)}>
                          {t(item.name)}
                        </NavItem>
                      </li>)
                  } else return (
                    <li key={item.path}>
                      <NavItem to={item.path}
                               onClick={() => setOpen(false)}>
                        {t(item.name)}
                      </NavItem>
                    </li>
                  )
                })}
              </ul>
            </nav>}
            <HeaderLinkGroup>
              <AvatarDropdown isLanding={isLanding}>
                {encryptedToken?.role === Role.jobSeeker && !encryptedToken?.blocked &&
                  <AvatarDropdownButton
                    onClick={handleClick}
                  >
                    <AvatarDropdownIcon>
                      <Icon name={'settings'}/>
                    </AvatarDropdownIcon>
                    {t('header.settings')}
                  </AvatarDropdownButton>}
                <AvatarDropdownButton onClick={handleLogout}>
                  <AvatarDropdownIcon>
                    <Icon name={'logout'}/>
                  </AvatarDropdownIcon>
                  {t('header.logout')}
                </AvatarDropdownButton>
              </AvatarDropdown>
              {!isNetbook && <Notification isLanding={isLanding}/>}
              <LangSwitch isLanding={isLanding}/>
            </HeaderLinkGroup>
          </Ul>
        </>
      )}
    </>
  );
});
