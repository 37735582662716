import {NoDataWrapper} from './styles'
import {ReactComponent as NoDataBellIcon} from '../../../../../assets/svg/no-data-bell.svg'
import {Heading, Text} from '../../../../components'
import {useTranslation} from 'react-i18next'

const NoData = () => {
  const {t} = useTranslation()

  return (
    <NoDataWrapper data-testid='no-data-block'>
      <NoDataBellIcon/>
      <div>
        <Heading variant='h3'>{t('notifications.noData.title')}</Heading>
        <Text>{t('notifications.noData.body')}</Text>
      </div>
    </NoDataWrapper>
  );
};

export default NoData;
