import {useQuery} from "react-query";
import {ResponseWithPagination} from "../../../../../types";
import {
  GetJoResponse
} from "../../../employer/JoList/types";
import {ServerError} from "../../../../../api/types";

import {
  getCommitteeInformation,
  getCommitteeJo, getEvaluation, getEvaluationReview, getEvaluationReviewByHead,
  getEvaluationsHeadList,
  getEvaluationsList
} from "./requests";
import {
  GetCommitteeInfo,
  GetCommitteeJoPayload,
  GetEvaluationListPayload,
  GetEvaluationReview,
  GetEvaluationReviewByHead,
  GetEvaluationsHeadListResponse,
  GetEvaluationsListResponse
} from "./types";

export const useGetCommitteeJo = (data: GetCommitteeJoPayload) => (
  useQuery<ResponseWithPagination<GetJoResponse[]>, ServerError>(
    ['useGetCommitteeJo', data], () => getCommitteeJo(data), {
      refetchOnWindowFocus: false,
      retry: false
    })
)

export const useGetEvaluations = (data: GetEvaluationListPayload) => (
  useQuery<ResponseWithPagination<GetEvaluationsListResponse[]>, ServerError>(
    ['useGetEvaluations', data],
    () => getEvaluationsList(data),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: false,
      cacheTime: 0,
      keepPreviousData: true,
    })
)

export const useGetHeadEvaluationsList = (data: GetEvaluationListPayload) => (
  useQuery<ResponseWithPagination<GetEvaluationsHeadListResponse[]>, ServerError>(
    ['useGetHeadEvaluationsList', data],
    () => getEvaluationsHeadList(data),
    {
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true
    })
)

export const useGetEvaluation = (id: number) => (
  useQuery<GetEvaluationsListResponse, ServerError>(['getEvaluation', id],
    () => getEvaluation(id),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
)

export const useGetEvaluationReview = (id: number) => (
  useQuery<GetEvaluationReview, ServerError>(['getEvaluationReview', id],
    () => getEvaluationReview(id),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
)

export const useGetEvaluationReviewByHead = (id: number) => (
  useQuery<GetEvaluationReviewByHead, ServerError>(['getEvaluationReviewByHead', id],
    () => getEvaluationReviewByHead(id),
    {
      retry: false,
    }
  )
)

export const useGetCommitteeInformation = (id: string | number) => (
  useQuery<GetCommitteeInfo, ServerError>(['getCommitteeMember', id],
    () => getCommitteeInformation(Number(id)),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!id
    }
  )
)