import login from './login.json'
import errors from './errors.json'
import common from './common.json'
import myList from './myList.json'
import myListDetails from './myListDetails.json'
import dashboard from './dashboard.json'
import resumeFilled from './resumeFilled.json'
import locationsInput from './locationsInput.json'
import aboutMe from './aboutMe.json'
import certifications from './certifications.json'
import education from './education.json'
import footer from './footer.json'
import header from './header.json'
import languages from './languages.json'
import personalInformation from './personalInformation.json'
import skills from './skills.json'
import workExperience from './workExperience.json'
import emailConfirm from './emailConfirm.json'
import forgot from './forgot.json'
import landingEmployer from './landingEmployer.json'
import landingMain from './landingMain.json'
import register from './register.json'
import resume from './resume.json'
import privacy from './privacy.json'
import terms from './terms.json'
import userSettings from './userSettings.json'
import nav from './nav.json'
import searchAside from './searchAside.json'
import hrPool from './hrPool.json'
import generalInfo from './generalInfo.json'
import adminDashboard from './adminDashboard.json'
import governmentEmployer from './governmentEmployer.json'
import modalWindow from './modalWindow.json'
import jobOpening from './jobOpening.json'
import jobApplication from './jobApplication.json'
import adminJSTable from './adminJSTable.json'
import adminJOTable from './adminJOTable.json'
import interview from './interview.json'
import offer from './offer.json'
import jsHistory from './jsHistory.json'
import notifications from './notifications.json'
import wizzardGuide from './wizzardGuide.json'
import landingStats from './landingStats.json'
import landingAboutWIS from './landingAboutWIS.json'
import employerJo from './employerJo.json'
import createJobOpening from './createJobOpening.json'
import hiring from './hiring.json'
import hiringRequests from './hiringRequests.json'
import faq from './faq.json'
import committee from './committee.json'
import shareMedia from './shareMedia.json'
import attachments from './attachments.json'
import avatar from './avatar.json'
import joLaborAttache from './joLaborAttache.json'
import blacklist from './blacklist.json'
import laDashboard from './laDashboard.json'
import receptionist from './receptionist.json'
import timeSlots from './timeSlots.json'
import attendees from './attendees.json'
import passportTravel from './passportTravel.json'
import adminCommittee from './adminCommittee.json'

const translations = {
  login,
  errors,
  common,
  myList,
  dashboard,
  resumeFilled,
  locationsInput,
  aboutMe,
  certifications,
  education,
  footer,
  header,
  languages,
  personalInformation,
  skills,
  workExperience,
  emailConfirm,
  forgot,
  landingEmployer,
  landingMain,
  register,
  resume,
  privacy,
  terms,
  userSettings,
  nav,
  myListDetails,
  hrPool,
  generalInfo,
  searchAside,
  adminDashboard,
  governmentEmployer,
  modalWindow,
  jobOpening,
  jobApplication,
  adminJSTable,
  adminJOTable,
  interview,
  jsHistory,
  offer,
  notifications,
  wizzardGuide,
  landingStats,
  landingAboutWIS,
  employerJo,
  createJobOpening,
  hiringRequests,
  hiring,
  faq,
  committee,
  shareMedia,
  attachments,
  avatar,
  joLaborAttache,
  blacklist,
  laDashboard,
  receptionist,
  timeSlots,
  attendees,
  passportTravel,
  adminCommittee
}

export default translations
