import styled, {css} from 'styled-components';
import themeMain from '../../../theme/themeMain';
import {devices} from "../../../theme/devices";
import rtl from "styled-components-rtl";

type DropdownContentProps = {
  active: boolean;
};

type AvatarUserNameProps = {
  isLanding: boolean;
}

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  > button {
    background: #ffffff;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: none;
    vertical-align: middle;
    ${ rtl`margin-left: auto;` }; /* Strictly for positioning */

    &.active {
      box-shadow: 0 0 0 2px #0093d3;
    }
  }
`;

export const DropdownContent = styled.div<DropdownContentProps>`
  display: flex;
  flex-direction: column;
  min-width: max-content;
  padding: 2px 0;
  background: ${themeMain.colors.white};
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  position: absolute;
  top: 35px;
  ${rtl`right: 0;`};
  width: 182px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;

  ${({active}) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(12px);
    `}
  
  button{
    margin-inline: 0 !important;
  }
`;

export const AvatarUserName = styled.div<AvatarUserNameProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-inline-end: 20px;
  
  p{
    font-size: 16px;
    margin: 0;
    color: ${themeMain.colors.black};
    @media screen and ${devices.laptop} {
      color: ${(props) => (props.isLanding ? themeMain.colors.white : themeMain.colors.black)};
    }
    
    &:first-child{
      font-weight: bold;
      margin-bottom: 4px;
    }
    &:last-child{
      font-weight: normal;
      color: ${themeMain.colors.black};
      @media screen and ${devices.laptop} {
        color: ${(props) => (props.isLanding ? '#CCCCCC' : themeMain.colors.black)};
      }
    }
  }
`