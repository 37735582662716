import {makeAutoObservable, toJS} from 'mobx'
import api from '../api/axiosInterceptor'
import {GET_USER_HISTORY} from '../constants/api'
import getErrorText from '../utils/getErrorText'
import {ResponseWithPagination} from "../types";

export type UserHistoryType =
  "LOGGED_IN"
  | "LOGGED_OUT"
  | "JOB_APPLICATION"
  | "OFFER"
  | "INTERVIEW"
  | "BAN"
  | "UNBAN"

type LOGGED_IN_DATA = {
  eventStatus?: 'ACCEPTED' | 'REJECTED';
  ip?: string
  banReason?: string
  companyName?: string
  interviewId?: string
  jobId?: string
  jobName?: string
  startDate?: string
  endDate?: string
  offerId?: string
  status?: string
}

export type HistoryEvent = {
  id: number | string
  userId: number
  secId: number
  type: UserHistoryType
  createdAt: string
  data: LOGGED_IN_DATA
}

type Filters = {
  type?: UserHistoryType[]
  startDate?: string
  endDate?: string
  historyStartTime?: string
  historyEndTime?: string
  page?: number
  size?: number
}

interface JsHistoryPoolStore {
  loading: boolean
  error: string
  filters: Filters
  events: HistoryEvent[]
  setLoading: (loading: boolean) => void
  setHistoryEvents: (events: HistoryEvent[]) => void
  setError: (error: string) => void
  setFilters: (filters: { size: number; page: number }) => void
}

const initialFilters = {
  size: 10,
  page: 0
}

class JSHistory implements JsHistoryPoolStore {
  error: string = ''
  loading: boolean = true
  filters: Filters = initialFilters
  events: HistoryEvent[] = []
  total: number = 1

  constructor() {
    makeAutoObservable(this)
  }

  getAllFilters = () => {
    return this.filters
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  setHistoryEvents = (events: HistoryEvent[]) => {
    this.events = events
  }

  setError = (error: string) => {
    this.error = error
  }

  setTotal = (arg: number) => {
    this.total = arg
  }

  setFilters = (filters: Filters, cleaner: boolean = false): void => {
    if (cleaner) this.events = []
    const {historyStartTime, historyEndTime, ...restFilters} = filters
    const cStartDate = ('' + historyStartTime)?.split('GMT')[0]
    const cEndDate = ('' + historyEndTime)?.split('GMT')[0]

    this.filters = {
      ...initialFilters,
      ...(historyStartTime && {startDate: cStartDate}),
      ...(historyEndTime && {endDate: cEndDate}),
      ...restFilters
    }
  }

  fetchHistory = async (jobseekerId: string) => {
    this.setLoading(true)
    this.setError('')
    try {
      const mappedFilters = Object.keys(this.filters).reduce((acc, curr) => {
        const key = curr as keyof Filters
        if (Array.isArray(this.filters[key])) {
          if ((this.filters[key] as []).length) {
            return {
              ...acc,
              [key]: this.filters[key]
            }
          }
        }
        if (!Array.isArray(this.filters[key]) && this.filters[key]) {
          return {
            ...acc,
            [key]: this.filters[key]
          }
        }
        return acc
      }, {
        userId: jobseekerId,
        page: 0
      } as Filters)
      const response = await api.post<ResponseWithPagination<HistoryEvent[]>>(GET_USER_HISTORY, mappedFilters)
      const concatArr = [...toJS(this.events), ...response.data.content]
      const uniqArr: HistoryEvent[] = [...new Map(concatArr.map((item) => [item.id, item])).values()]
      this.setHistoryEvents(uniqArr)
      this.setTotal(response.data.totalPages)
    } catch (e) {
      this.setError(getErrorText(e?.data?.messages?.[0]?.key?.value))
    } finally {
      this.setLoading(false)
    }
  }
}

const jsHistoryStore = new JSHistory()
export default jsHistoryStore
