import styled from 'styled-components';
import themeMain from '../../../theme/themeMain';
import {fonts} from '../../../theme/fonts';
import rtl from "styled-components-rtl";

export const Label = styled.label`
  margin-bottom: 0 !important;
`;

export const Component = styled.div`
  display: flex;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    font: bold 14px/18px ${fonts.openSans};
    background: ${themeMain.colors.gray.light};
    color: #fff;
    min-height: 42px;
    padding: 9px 12px 8px;
    border-radius: 4px;
    cursor: pointer;

    svg {
      ${rtl`margin-right: 8px`};
    }
  }

  label {
    &:after {
      content: '' !important;
    }
  }

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    width: 1px;
    cursor: pointer;

    &:checked {
      & ~ span {
        background: ${themeMain.colors.green} !important;
      }
    }
  }
`;
