import styled from 'styled-components'
import themeMain from '../../../theme/themeMain'

export const HeaderAvatarPlugWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${themeMain.colors.green};
  border-radius: 50%;
  padding: 7px;
  color: ${themeMain.colors.white};
  
  &:before {
    content: '';
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    border: 1px solid ${themeMain.colors.white};
    border-radius: 50%;
  }
`

export const HeaderAvatarPlug = styled.div`
  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    border: 1px solid ${themeMain.colors.white};
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 6px;
    width: 19px;
    height: 10px;
    border: 1px solid ${themeMain.colors.white};
    border-bottom: none;
    border-radius: 50% 50% 49% 51% / 100% 100% 0% 0%;
  }
`
