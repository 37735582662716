import styled from 'styled-components';
import {devices} from '../../../theme/devices';
import rtl from "styled-components-rtl";

export const SuccessMessageComponent = styled.div`
  width: 100%;
  min-height: 62px;
  padding: 5px 15px;

  > div {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;

    @media screen and ${devices.tablet} {
      padding: 0 30px;
      width: 768px;
      flex-grow: unset;
    }

    @media screen and ${devices.laptop} {
      width: 1366px;
    }
  }

  p {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
    word-break: break-word;

    @media screen and ${devices.tablet} {
      max-width: 700px;
    }

    @media screen and ${devices.laptop} {
      max-width: 930px;
    }

    @media screen and ${devices.desktop} {
      max-width: 1100px;
    }
  }

  i {
    font-size: 20px;

    &:first-child {
      ${rtl`margin-right: 10px;`};
    }

    &:last-child {
      ${rtl`margin-left: auto;`};
      cursor: pointer;
    }
  }

  &.success {
    background-color: #D6FFD8;
  }

  &.error {
    background-color: #fb9996;
  }

  &.warning {
    background-color: #F8AF70;
  }

  &.maintenance {
    background-color: #FFF3C9;
  }
`;
