import {
  AppliedJSResponse
} from "../app/private/employer/EmployerApplicants/types";
import {makeAutoObservable} from "mobx";
import api from "../api/axiosInterceptor";
import {GET_APPLIED_JS} from "../constants/api";
import {ResponseWithPagination} from "../types";

const APPLICANTS_PER_PAGE = 5

type SelectedApplicant = Record<number, boolean>

interface ScheduleInterviewStore {
  applicants: AppliedJSResponse[]
  displayedApplicants: AppliedJSResponse[]
  error: string
  loading: boolean
  isSelectedAll: boolean
  hasDisplayedApplicants: boolean
  selectedApplicants: SelectedApplicant
  selectedApplicantsId: number[]
  resetStore: () => void
  setDisplayedApplicants: (applicants: AppliedJSResponse[]) => void
  setSelectedApplicants: (obj: SelectedApplicant) => void
  setError: (error: string) => void
  setLoading: (loading: boolean) => void
  fetchApplicants: (shortlistedJobId: string) => Promise<void>
  setApplicants: (applicants: AppliedJSResponse[]) => void
  removeApplicants: () => void
}

class ScheduleInterview implements ScheduleInterviewStore {
  constructor() {
    makeAutoObservable(this)
  }

  selectedApplicants: SelectedApplicant = {}
  displayedApplicants: AppliedJSResponse[] = []
  applicants: AppliedJSResponse[] = []
  loading: boolean = true
  error: string = ''

  resetStore = () => {
    this.applicants = []
    this.displayedApplicants = []
    this.selectedApplicants = {}
    this.error = ''
  }

  setSelectedApplicants = (obj: SelectedApplicant, clear?: boolean) => {
    this.selectedApplicants = {
      ...(clear ? {} : this.selectedApplicants),
      ...obj
    }
  }

  fetchApplicants = async (shortlistedJobId: string) => {
    this.setLoading(true)
    try {
      const res = await api.post<ResponseWithPagination<AppliedJSResponse[]>>(GET_APPLIED_JS, {
        shortlistedJobId,
        size: 10000
      })
      this.setApplicants(res.data.content)
      this.setDisplayedApplicants()
    } catch (e) {
      this.setApplicants([])
      this.setError(e?.data?.messages?.[0]?.key?.value ?? 'fail.internal')
    } finally {
      this.setLoading(false)
    }
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  setError = (error: string) => {
    this.error = error
  }

  setApplicants = (applicants: AppliedJSResponse[]) => {
    this.applicants = applicants
  }

  setDisplayedApplicants = () => {
    if (this.hasMore) {
      const length = this.displayedApplicants.length
      this.displayedApplicants = [
        ...this.displayedApplicants,
        ...this.applicants.slice(length, length + APPLICANTS_PER_PAGE)
      ]
    }
  }

  removeApplicants = () => {
    this.applicants = this.applicants.filter((item) => !this.selectedApplicantsId.includes(item.userId))
    this.displayedApplicants = this.displayedApplicants.filter((item) => !this.selectedApplicantsId.includes(item.userId))
    this.selectedApplicants = {}
  }

  get hasMore() {
    return this.displayedApplicants.length < this.applicants.length
  }

  get isSelectedAll() {
    return (Object.keys(this.selectedApplicants).length === this.applicants.length) && !Object.values(this.selectedApplicants).
      some((item) => !item)
  }

  get hasDisplayedApplicants() {
    return !!this.displayedApplicants.length
  }

  get selectedApplicantsId() {
    return Object.entries(this.selectedApplicants).
      reduce((acc: number[], [key, value]) => {
        if (value) return [...acc, +key]
        return acc
      }, [])
  }
}

const scheduleInterviewStore = new ScheduleInterview();

export default scheduleInterviewStore;