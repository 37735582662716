import React from 'react';
import {
  AnalyticsBoxComponent,
  AnalyticsNumberComponent,
  AnalyticsMonthComponent,
  AnalyticsTitleComponent
} from './styles';

type ContainerProps = {
  children?: React.ReactNode;
};

export const AnalyticsBox = ({children, ...rest}: ContainerProps) => {
  return <AnalyticsBoxComponent {...rest}>{children}</AnalyticsBoxComponent>;
};

export const AnalyticsNumber = ({children, ...rest}: ContainerProps) => {
  return <AnalyticsNumberComponent {...rest}>{children}</AnalyticsNumberComponent>;
};

export const AnalyticsMonth = ({children, ...rest}: ContainerProps) => {
  return <AnalyticsMonthComponent {...rest}>{children}</AnalyticsMonthComponent>;
};

export const AnalyticsTitle = ({children, ...rest}: ContainerProps) => {
  return <AnalyticsTitleComponent {...rest}>{children}</AnalyticsTitleComponent>;
};
