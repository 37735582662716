import styled, {css} from 'styled-components';
import {devices} from '../../../theme/devices';
import rtl from "styled-components-rtl";

type StyledComponentProps = {
  separator: 'double' | 'tripple' | 'quadro';
};

export const FieldsetComponent = styled.fieldset<StyledComponentProps>`
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;

  ${({separator}) =>
    separator === 'double' &&
    css`
      > div {
        flex: 1 1 auto;
        max-width: calc(50% - 8px);
      }
    `}

  ${({separator}) =>
    separator === 'tripple' &&
    css`
      > div {
        flex: 1 1 auto;

        @media screen and ${devices.tablet} {
          max-width: calc(33.3333% - 5px);
        }
      }
    `}

    ${({separator}) =>
    separator === 'quadro' &&
    css`
      > div {
        flex: 1 1 auto;

        @media screen and ${devices.tablet} {
          max-width: calc(25% - 12px);
        }
      }
    `}
  
  &.sm-single-separator{
    > div{
      @media screen and ${devices.maxMobile} {
        width: 100%;
        max-width: none;
      }
    }
  }

  .rc-slider-mark{
    
      span{
        &:first-child {
          ${rtl`transform: translateX(-10%) !important;`}
        }
        
        &:last-child {
          ${rtl`transform: translateX(-90%) !important;`}
        }
      }
    
  }
  
`;
