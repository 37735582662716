import {Text} from '../Typography';
import {SuccessMessageComponent} from './styles';
import {observer} from 'mobx-react'
import useStores from '../../../hooks/useStores'
import {useEffect} from 'react'
import {useTranslation} from 'react-i18next'

export const SuccessMsg = observer(() => {
  const {t} = useTranslation()
  const {notificationStore: {text, variant, timer = 5000, additionalText, setText, textVars}} = useStores()
  const onClose = () => {
    setText('')
  };

  useEffect(() => {
    if (timer){
      let timeOut: NodeJS.Timeout | number
      if (text) {

        timeOut = setTimeout(() => setText(''), timer)}
      return () => {
        if (timer) clearTimeout(timeOut)
      }
    }
  }, [text, timer, setText])

  return (
    <SuccessMessageComponent
      style={{
        display: !text ? 'none' : 'flex',
        alignItems: !text ? 'unset' : 'center'
      }}
      className={variant}
    >
      <div>
        <i className={variant === 'success' ? 'mi mi-c-check-2' : 'mi mi-c-warning'}/>
        <div>
          <Text variant='regular'>{t(text,{...textVars})}</Text>
          <Text variant='regular'>{t(additionalText)}</Text>
        </div>
        <i className='mi mi-e-remove' onClick={onClose}/>
      </div>
    </SuccessMessageComponent>
  );
});
