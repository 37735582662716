import {makeAutoObservable} from 'mobx'
import getErrorText from '../utils/getErrorText'
import {Degree} from '../types'
import {getEnv} from "../utils/getEnv";
import {
  getJobOpeningsList
} from "../app/private/shared/pages/JobOpening/api/queries";

type JobOpeningType = 'AJEER' | 'LABOR' | 'STANDARD'

type Card = {
  id: number
  companyLogoPath: string
  jobTitle: string
  companyName: string
  city: string
  requiredExperience: number
  skills: string[]
  languages: string[]
  salaryRate: number
  requiredDegree: Degree
  createdAt: string
  status: 'OPEN' | 'DISABLED' | 'EXPIRED' | null
}

export type Filters = {
  searchText?: string
  location?: string
  yearsOfExperienceCriterias?: string[]
  educationCriterias?: string[]
  employmentTypeCriteria?: string
  industries?: string[]
  jobOpeningTypes?: JobOpeningType[]
  page?: number
  size?: number
}

interface JobOpeningStore {
  total: number
  loading: boolean
  error: string
  pageAmount: number
  filters: Filters
  cards: Card[]
  selectedResumeId: number | null
  setLoading: (loading: boolean) => void
  setCards: (cards: Card[]) => void
  setTotal: (size: number, total: number) => void
  setError: (error: string) => void
  setFilters: (filters: Filters) => void
  setSelectedResumeId: (id: number | null) => void
}

export type FetchCardsResponse = {
  total: number
  pageNumber: number
  content: Card[]
}

const initialFilters = {
  size: 9,
  searchText: '',
  location: '',
  yearsOfExperienceCriterias: [],
  educationCriterias: [],
  employmentTypeCriteria: '',
  industries: [],
  jobOpeningTypes: getEnv("REACT_APP_SHOW_JOB_OPENING_AJEER") === 'true' ? []
    : ['LABOR', 'STANDARD']
} as Filters

class JobOpeningPool implements JobOpeningStore {
  total: number = 1
  pageAmount = 1
  error: string = ''
  loading: boolean = true
  filters: Filters = initialFilters
  cards: Card[] = []
  selectedResumeId: number | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  setCards = (cards: Card[]) => {
    this.cards = cards
  }

  setError = (error: string) => {
    this.error = error
  }

  setFilters = (filters: Filters): void => {
    this.filters = {
      ...initialFilters,
      ...filters
    }
  }

  setTotal = (size: number, total: number) => {
    this.total = Math.ceil(total / size)
  }

  fetchCards = async () => {
    if (this.filters['searchText'] && this.filters['searchText'].length < 3) return;
    this.filters['page'] = this.filters['page'] ? +this.filters['page'] - 1 : 0;

    this.setLoading(true)
    this.setError('')
    try {
      const mappedFilters = Object.keys(this.filters).reduce((acc, curr) => {
        const key = curr as keyof Filters
        if (Array.isArray(this.filters[key])) {
          if ((this.filters[key] as []).length) {
            return {
              ...acc,
              [key]: this.filters[key]
            }
          }
        }
        if (!Array.isArray(this.filters[key]) && this.filters[key]) {
          return {
            ...acc,
            [key]: this.filters[key]
          }
        }
        return acc
      }, {} as Filters)
      const response = await getJobOpeningsList(mappedFilters)
      this.setCards(response.data.content)
      this.setTotal(this.filters['size'] ?? 9, response.data.total)
    } catch (e) {
      this.setError(getErrorText(e?.data?.messages?.[0]?.key?.value))
    } finally {
      this.setLoading(false)
    }
  }

  setSelectedResumeId = (id: number | null) => {
    this.selectedResumeId = id
  }
}

const jobOpeningStore = new JobOpeningPool()
export default jobOpeningStore
