import React, {useRef, useState} from 'react';
import {useOnClickOutside} from '../../../hooks/useClickOutside';
import {
  AvatarUserName,
  DropdownContainer,
  DropdownContent
} from './styles';
import {HeaderAvatar} from '../../components';
import {observer} from 'mobx-react';
import useStores from '../../../hooks/useStores';
import {useAuthInfo} from '../../../hooks/useAuthInfo';
import {Role} from '../../../utils/roles'
import {JOB_OPENING_AVATAR} from "../../../constants/api";
import Image from "../Image";
import {getEnv} from "../../../utils/getEnv";
import {useTranslation} from "react-i18next";
import {
  useGetReceptionist
} from "../../private/employer/LaborAttacheOffice/api/interview/queries";
import {
  useGetCommitteeInformation
} from "../../private/committee/api/JOList/queries";
import {GlobalRole} from "../../public/LandingMain/LandingMain";

type DropdownProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  isLanding?: boolean;
};

export const AvatarDropdown = observer(({children, isLanding = false}: DropdownProps) => {
  const {userStore} = useStores();
  const {t} = useTranslation()
  const {
    user: {avatar, firstName},
  } = userStore;
  const ref = useRef<HTMLDivElement>(null);

  const token = useAuthInfo()

  const [isActive, setIsActive] = useState(false);

  const {
    data = {
      name: ''
    }
  } = useGetReceptionist(token.sub, token.role === Role.receptionist)

  const {data: committeeInfo} = useGetCommitteeInformation(token.role === Role.committee ? token.sub : '')

  const onToggle = () => {
    setIsActive((prevState) => !prevState);
  };

  const onClose = () => {
    setIsActive(false);
  };

  useOnClickOutside(ref, onClose);

  const renderName = (type: GlobalRole) => {
    switch (type) {
      case Role.employer:
        return token?.employerName
      case Role.receptionist:
        return data?.name
      case Role.committee:
        return committeeInfo?.name
      case Role.admin:
        return undefined
      default:
        return firstName
    }
  }

  return (
    <div style={{display: 'flex', alignItems: 'center', marginInlineEnd: 20}}>
      <AvatarUserName isLanding={isLanding}>
        <p>{renderName(token.role as GlobalRole)}</p>
        {token.role === Role.employer ?
          <p>{t(`common.roles.${token?.['employer-type']}`)}</p>
          : <p>{t(`common.roles.${token.role}`)}</p>
        }
      </AvatarUserName>
      <DropdownContainer ref={ref}>
        <button
          onClick={onToggle}
          className={`menu-trigger ${isActive ? 'active' : ''}`}
          style={{height: '38px', width: '38px', justifyContent: 'center'}}
          data-testid='toggle-btn'
        >
          {!avatar ? (
            <HeaderAvatar/>
          ) : (
            <Image
              src={`${getEnv('REACT_APP_API_ENDPOINT')}${JOB_OPENING_AVATAR}/${avatar}`}
              alt='avatar'
              loaderSize='small'
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '50%',
              }}/>
          )}
        </button>
        <DropdownContent active={isActive}>{children}</DropdownContent>
      </DropdownContainer>
    </div>
  );
});
