import styled, {css} from 'styled-components';
import {NavLink} from 'react-router-dom';
import themeMain from "../../../theme/themeMain";

type StyledComponentProps = {
  variant: 'primary' | 'secondary';
  size?: 'big' | 'medium' | 'small';
};

export const Component = styled(NavLink)<StyledComponentProps>`
  ${(props) => props.theme.links[props.variant]};

  ${({size}) =>
    size === 'big' &&
    css`
      min-height: 52px;
      padding: 12px 27px 11px;
      font-size: 16px;
    `}

  ${({size}) =>
          size === 'medium' &&
          css`
            min-height: 42px;
            padding: 13px 38px;
            font-size: 14px;
            font-weight: 600;
          `}

  ${({size}) =>
          size === 'small' &&
          css`
            min-height: 32px;
            padding: 5px 22px;
            font-size: 16px;
            font-weight: 600;
            text-transform: none;
          `}

  &.disabled {
    position: relative;
    background: ${themeMain.colors.gray.light};
    color: ${themeMain.colors.gray.dark};
    pointer-events: none;
    cursor: not-allowed;

    &:hover,
    &:focus {
      background: #e0e0e0;

      .tooltipText {
        visibility: visible;
      }
    }
`;
