import React, {SyntheticEvent} from 'react';
import {Component} from './styles';

export type LinkTextProps = {
  children: React.ReactNode;
  to: string;
  style?: React.CSSProperties;
  onClick?: (e: SyntheticEvent) => void;
  target?: '_blank'
};

export const LinkText = ({children, ...rest}: LinkTextProps) => {
  return <Component {...rest}>{children}</Component>;
};
