import styled from 'styled-components';
import themeMain from '../../../theme/themeMain';

export const Component = styled.a`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  background-color: ${themeMain.colors.green};
`;
