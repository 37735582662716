import React, {useEffect, useRef} from 'react';
import Chart, {ChartData, ChartOptions, ChartType} from 'chart.js/auto'

export type ChartProps = {
  id: string
  width?: number
  height?: number
  type: ChartType
  data: ChartData
  options?: ChartOptions
}

export const BaseChart = (props: ChartProps) => {
  const {id, width, height, type, data, options} = props
  const chartRef = useRef<Chart | null>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  const renderChart = () => {
    if (!canvasRef.current) return
    const context = canvasRef.current.getContext('2d')
    if (context) {
      chartRef.current = new Chart(context, {
        type,
        data,
        options: {
          responsive: true,
          ...options
        }
      })
    }
  }

  useEffect(() => {
    renderChart()
    return () => {
      chartRef.current?.destroy()
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.data = data
      chartRef.current.update()
    }
  }, [data])

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.options = {...options}
      chartRef.current.update()
    }
  }, [options])

  return (
    <div style={{width, height}}>
      <canvas
        id={id}
        ref={canvasRef}
      />
    </div>
  );
};