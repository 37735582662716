import {HeaderContainer} from '../Container';
import {HeaderComponent} from './styles';
import {Burger} from './burger';
import logo_new from '../../../assets/logoNew.svg'
import landing_logo_new from '../../../assets/logoNewLanding.svg'
import {Link} from "react-router-dom";
import {useAuthInfo} from "../../../hooks/useAuthInfo";
import {Role} from "../../../utils/roles";
import {useScreenSize} from '../../../hooks/useScreenSize'
import Notification
  from '../../private/jobseeker/components/Notifications/notificationBell'
import {useState} from "react";

type HeaderProps = {
  isPrivate?: boolean;
  isLanding: boolean;
  isSideMenu?: boolean;
};

const getLogo = (isLanding: boolean, isSideMenu: boolean) => {
  return isLanding && !isSideMenu? landing_logo_new : logo_new
}


export const Header = ({isPrivate, isLanding}: HeaderProps) => {
  const {...encryptedToken} = useAuthInfo()
  const isNetbook = useScreenSize(1024)
  // TODO move to MobX isSideMenuOpen
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);

  return (
    <HeaderComponent isLanding={isLanding}>
      <HeaderContainer>
        {!isPrivate ? (
          <>
            <Link to='/' style={{marginInlineEnd: 'auto'}}>
              <div className='logo'>
                <img src={getLogo(isLanding, isSideMenuOpen)} alt="logo"/>
              </div>
            </Link>
          </>
        ) : (
          <>
            <Link
              style={{marginInlineEnd: 'auto'}}
              to={encryptedToken.role === Role.jobSeeker ? '/account/adminDashboard' : '/admin/adminDashboard'}>
              <div className='logo'>
                <img src={getLogo(isLanding, isSideMenuOpen)} alt="logo"/>
              </div>
            </Link>
          </>
        )}
        {isNetbook &&  <Notification isLanding={isLanding}/>}
        <Burger isPrivate={isPrivate} setOpen={(open) => setSideMenuOpen(open)} open={isSideMenuOpen}/>
      </HeaderContainer>
    </HeaderComponent>
  );
};

