import {devices} from './devices';
import {fonts} from './fonts';

const boxShadow = {
  block: '0px 3px 16px rgba(0, 0, 0, 0.16)'
}

const colors = {
  green: '#00C48C',
  greenHover: 'rgba(0,196,140,0.7)',
  lightGreen: '#E6FAF4',
  red: '#d9534f',
  gray: {
    light: '#E0E0E0',
    medium: '#777777',
    dark: '#4A4A4A',
  },
  grayStatusText: '#787A93',
  grayStatusBackground: '#F2F2F5',
  redStatusText: '#EB5757',
  redStatusBackground: '#FCEAEA',
  orangeStatusText: '#FFB800',
  orangeStatusBackground: '#FFF8E6',
  white: '#fff',
  whiteSmoke: '#F8F8F8',
  black: '#000',
  blue: '#2684FF',
  blueBright: '#3363E0',
  blueLight: '#EBF0FE',
  orange: '#ef7308',
  gold: '#FFBD00',
  lightGold: '#FFDE7E',
  paleGold: '#FFD048',
  paleBlue: '#F8FBFD'
};

const fontWeight = {
  100: '100',
  200: '200',
  300: '300',
  400: '400',
  500: '500',
  600: '600',
  700: '700',
  800: '800',
  900: '900',
};

const typographyPrimary = {
  display: 'block',
  textDecoration: 'none',
  margin: '0 0 10px',
  fontFamily: fonts.openSans,
};

const textPrimary = {
  display: 'block',
  textDecoration: 'none',
  margin: '0 0 10px',
  fontFamily: fonts.openSans,
  color: colors.gray.dark,
};

const btnBase = {
  appearance: 'none',
  display: 'inline-flex',
  justifyContent: 'center',
  textTransform: 'uppercase',
  alignItems: 'center',
  textDecoration: 'none',
  fontFamily: `${fonts.openSans} !important`,
  fontWeight: 'bold',
  fontSize: '14px',
  minHeight: '42px',
  padding: '12px 12px 11px',
  borderRadius: '4px',
  cursor: 'pointer',
  border: 0,
  boxSizing: 'border-box',
  transition: 'all 0.3s ease',
  transitionProperty: 'background, font-size, color, box-shadow',
  minWidth: '100px',
  width: 'auto',
  '&:disabled, &[disabled]': {
    cursor: 'not-allowed',
  },
  '&:focus, &:active, &:hover': {
    outline: 'none',
    textDecoration: 'none',
    border: 0
  },
  [`@media ${devices.laptop}`]: {},
  [`@media ${devices.tablet}`]: {},
};

const linkTextBase = {
  appearance: 'none',
  alignItems: 'center',
  textDecoration: 'none',
  fontFamily: fonts.openSans,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  color: colors.green,
  cursor: 'pointer',
  boxSizing: 'border-box',
  transition: 'all 0.3s ease',
  transitionProperty: 'background, font-size, color',
  width: 'auto',
  '&:disabled, &[disabled]': {
    cursor: 'not-allowed',
  },
  '&:focus, &:active, &:hover': {
    outline: 'none',
    textDecoration: 'none',
    border: 0,
  },
  [`@media ${devices.laptop}`]: {},
  [`@media ${devices.tablet}`]: {},
};

const linkButtonBase = {
  appearance: 'none',
  display: 'inline-flex',
  justifyContent: 'center',
  textTransform: 'uppercase',
  alignItems: 'center',
  textDecoration: 'none',
  fontFamily: `${fonts.openSans} !important`,
  fontWeight: 'bold',
  fontSize: '14px',
  minHeight: '42px',
  padding: '12px 12px 11px',
  borderRadius: '4px',
  cursor: 'pointer',
  border: 0,
  boxSizing: 'border-box',
  transition: 'all 0.3s ease',
  transitionProperty: 'background, font-size, color',
  minWidth: '100px',
  width: 'auto',
  '&:disabled, &[disabled]': {
    cursor: 'not-allowed',
  },
  '&:focus, &:active, &:hover': {
    outline: 'none',
    textDecoration: 'none',
    border: 0,
  },
  [`@media ${devices.laptop}`]: {},
  [`@media ${devices.tablet}`]: {},
};

const inputBase = {
  fontFamily: `${fonts.openSans} !important`,
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '1',
  padding: '10px 11px 11px 11px',
  background: colors.white,
  border: `1px solid ${colors.gray.light}`,
  borderRadius: '4px',
  minHeight: '42px',
  maxHeight: '42px',
  boxSizing: 'border-box',
  width: '100%',
  '&:focus, &:active, &:hover': {
    borderColor: colors.gray.dark,
    outline: 'none',
  },
  [`@media ${devices.laptop}`]: {},
  [`@media ${devices.tablet}`]: {},
};

const themeMain = {
  colors,
  boxShadow,
  input: {
    ...inputBase,
  },

  dir: 'ltr',

  textLink: {
    ...linkTextBase,
  },

  buttons: {
    primary: {
      ...btnBase,
      backgroundColor: colors.green,
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.greenHover
      }
    },
    secondary: {
      ...btnBase,
      color: colors.green,
      backgroundColor: colors.white,
      boxShadow: '0px 0px 1px 1px #00C48C inset',

      '&:focus, &:active, &:hover': {
        color: colors.white,
        backgroundColor: colors.green,
      },
      '&[disabled]': {
        boxShadow: 'none',
      },
    },
    'outline-green': {
      ...btnBase,
      background: 'transparent',
      border: `1px solid ${colors.green}`,
      color: colors.green,

      '&:focus, &:active, &:hover': {
        color: colors.white,
        backgroundColor: colors.green,
      },
    },
    'outline-red': {
      ...btnBase,
      background: 'transparent',
      border: `1px solid ${colors.red}`,
      color: colors.red,

      '&:focus, &:active, &:hover': {
        color: colors.white,
        backgroundColor: colors.red,
      },
    },
    gold: {
      ...btnBase,
      background: colors.gold,
      color: colors.white,
    },
    red: {
      ...btnBase,
      background: colors.red,
      color: colors.white,
    },
    'text-green': {
      ...linkTextBase,
      background: 'transparent',
      color: colors.green,
      border: "none",
      fontSize: 16,
      fontWeight: 600,

      '&:focus, &:active, &:hover': {
        opacity: 0.7
      },

    }
  },

  links: {
    primary: {
      ...linkButtonBase,
      backgroundColor: colors.green,
      color: colors.white,
      '&:hover': {
        backgroundColor: colors.greenHover
      }
    },
    secondary: {
      ...linkButtonBase,
      color: colors.green,
      backgroundColor: colors.white,
      boxShadow: '0px 0px 1px 1px #00C48C inset',

      '&:focus, &:active, &:hover': {
        color: colors.white,
        backgroundColor: colors.green,
      },
    },
  },

  typography: {
    h1: {
      ...typographyPrimary,
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: fontWeight[700],
      color: colors.black,
      [`@media ${devices.tablet}`]: {},
    },
    h2: {
      ...typographyPrimary,
      fontSize: '24px',
      lineHeight: '30px',
      fontWeight: fontWeight[700],
      color: colors.black,
      [`@media ${devices.tablet}`]: {},
    },
    h3: {
      ...typographyPrimary,
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: fontWeight[700],
      color: colors.black,
      [`@media ${devices.tablet}`]: {},
    },
    h4: {
      ...typographyPrimary,
      fontSize: '16px',
      lineHeight: '26px',
      fontWeight: fontWeight[700],
      [`@media ${devices.tablet}`]: {},
    },
  },

  text: {
    big: {
      ...textPrimary,
      fontSize: '18px',
      lineHeight: '30px',
      fontWeight: fontWeight[400],
      [`@media ${devices.tablet}`]: {},
    },
    regular: {
      ...textPrimary,
      fontSize: '14px',
      lineHeight: '22px',
      fontWeight: fontWeight[400],
      [`@media ${devices.tablet}`]: {},
    },
    'regular-l': {
      ...textPrimary,
      fontSize: '14px',
      lineHeight: '26px',
      fontWeight: fontWeight[400],
      [`@media ${devices.tablet}`]: {},
    },
    'regular-bold': {
      ...textPrimary,
      fontSize: '14px',
      fontWeight: fontWeight[600],
    }
  },
};

export default themeMain;
