import styled from 'styled-components'
import themeMain from '../../../theme/themeMain'

export const Wrapper = styled.div`
  input {
    appearance: none;
    margin: 0;
    background: transparent;
    position: absolute;
    
    &:disabled{
      &+label{
        cursor: not-allowed;
      }
    }
  }

  label {
    position: relative;
    padding-left: 32px;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid ${themeMain.colors.gray.light};
    }

    &.error {
      &::before {
        border-color: ${themeMain.colors.red};
      }
    }
  }

  input[type="radio"]:checked + label {
    &::before {
      border-color: ${themeMain.colors.green};
      background: #F0F5FF;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 7px;
      transform: translateY(-50%);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: ${themeMain.colors.green};
    }

    &.error {
      &::before {
        border-color: ${themeMain.colors.red};
      }
    }
  }
`;
