import React, {InputHTMLAttributes} from 'react';
import {Component, Label, CheckboxContainer, StyledCheckbox} from './styles';

export interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  testId?: string
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      testId,
      className,
      type = 'checkbox',
      label,
      ...props
    },
    ref
  ) => {

    return (
      <Component className={className} {...props} >
        <Label>
          <CheckboxContainer>
            <input
              data-testid={testId}
              ref={ref}
              type={type}
              {...props}
            />
            <StyledCheckbox>
              <i className='mi mi-check'/>
            </StyledCheckbox>
          </CheckboxContainer>
          <span>{label}</span>
        </Label>
      </Component>
    );
  }
);
