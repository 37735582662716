import {GET_INTERVIEW, GET_INTERVIEW_LOCATION, GET_JOB} from '../constants/api'
import api from '../api/axiosInterceptor'
import {makeAutoObservable} from 'mobx'

type DateRange = {
  startDate: string
  endDate: string
}

type FetchInterviewResponse = {
  id: string
  jobSeekerId: string
  employerId: string
  jobOpeningId: string
  startDate: string
  endDate: string
}

type FetchJobInfoResponse = {
  logoFileName: string
  description: string
  expiryDate: string
  industry: string
  requiredExperience: string
  qualifications: string
  skills: [string]
  languages: [string]
  salaryRate: number
  companyOverview: string
  requiredDegree: string
  id: number
  userId: number
  title: string
  status: string
  companyName: string
  employmentType: string
  location: string
}

type FetchInterviewLocationResponse = {
  id: string
  locationType: string
  value: string
}

interface InterviewStore {
  loading: boolean
  error: string
  roomName: string
  jobOpeningName: string
  companyName: string
  dateRange: DateRange
}

class Interview implements InterviewStore {
  constructor() {
    makeAutoObservable(this);
  }

  loading = true
  error = ''
  roomName = ''
  jobOpeningName = ''
  companyName = ''
  dateRange = {
    startDate: '',
    endDate: ''
  }

  setRoomName = (name: string) => {
    this.roomName = name
  }

  setLoading = (loading: boolean) => {
    this.loading = loading
  }

  setError = (error: string) => {
    this.error = error
  }

  setDateRange = (dates: DateRange) => {
    this.dateRange = dates
  }

  setJobOpeningName = (name: string) => {
    this.jobOpeningName = name
  }

  setCompanyName = (name: string) => {
    this.companyName = name
  }

  fetchJobInfo = async (id: string) => {
    try {
      const {data: {companyName, title}} = await api.get<FetchJobInfoResponse>(GET_JOB(id))
      this.setCompanyName(companyName)
      this.setJobOpeningName(title)
    } catch (e) {
      console.log(e)
    }
  }

  fetchInterviewLocation = async (id: string) => {
    try {
      const {data: {value}} = await api.get<FetchInterviewLocationResponse>(GET_INTERVIEW_LOCATION(id))
      this.setRoomName(value)
    } catch (e) {
      console.log(e)
    }
  }

  fetchInterviewData = async (id: string) => {
    this.setLoading(true)
    try {
      const {data: {startDate, endDate, jobOpeningId}} = await api.get<FetchInterviewResponse>(GET_INTERVIEW(id))
      await Promise.allSettled([this.fetchInterviewLocation(id), this.fetchJobInfo(jobOpeningId)])
      this.setDateRange({
        startDate,
        endDate
      })
    } catch (e) {
      this.setError(e)
    } finally {
      this.setLoading(false)
    }
  }
}

const interviewStore = new Interview()
export default interviewStore
